import { i18n } from 'i18next'
import { observable } from 'mobx'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { concat, forkJoin, Observable, of } from 'rxjs'
import { concatAll, map, reduce } from 'rxjs/operators'
import Loader from './app/components/Loader'
import tagManager$, { TagManagerInterface } from './app/config/gtm-config'
import i18n$ from './app/config/i18n-config'
import Sentry from './app/config/sentry-config'
import { globalSpinner } from './app/helpers/globalSpinner'
// TODO: REMOVE IE11 COMPATIBILITY
import './app/helpers/ie11polyfill'
import { isDev } from './app/helpers/utils'
import { AsyncServiceTypes, DefaultServicePreload, DefaultServiceTypes } from './app/models/ServiceProvider'
import { QueryStringService } from './app/service/queryString/queryStringService'
import { StorageWrapper } from './app/service/storage/StorageWrapper'
import { CustomProvider } from './domain/components/CustomProvider'
import { ModalComponentInterface } from './domain/components/generic/Modal'
import { ACCEPTED_QUERY_STRING_KEY, NAVIGATOR_DENIED_QUERY_STRING_KEY } from './domain/environment/const'
import { GlobalsProvidedVarsInterface } from './domain/model/CustomGlobalsProvided'
import { ServiceProvidedInterface } from './domain/model/CustomServiceProvider'
import { GoogleApiPlaces } from './domain/service/GoogleApiPlaces'
import { PathNavigator } from './domain/service/PathNavigatorService'

/**
 * @desc starts service for get accepeted query-string params
 */
const QueryString = new QueryStringService(ACCEPTED_QUERY_STRING_KEY)
const acceptedParams = QueryString.getAcceptedParams()

const storage = new StorageWrapper()
const sessionStorage = new StorageWrapper(window.sessionStorage)

/**
 * @desc initialize service to manage path and navigate to localized route
 */
const PathNavigatorService = new PathNavigator(
  acceptedParams,
  ACCEPTED_QUERY_STRING_KEY.filter((item: string) => !NAVIGATOR_DENIED_QUERY_STRING_KEY.includes(item))
)

const loaderTarget = document.getElementById('loader')
ReactDOM.render(<Loader />, loaderTarget)

const globalModal = observable<ModalComponentInterface>({ visible: false })

const globals: GlobalsProvidedVarsInterface = {
  globalModal: globalModal,
  globalSpinner: globalSpinner(loaderTarget),
  queryStringParamsAccepted: acceptedParams
}

const initialService$: Observable<DefaultServicePreload> = of({
  sentry: Sentry,
  storage: storage,
  sessionStorage: sessionStorage,
  queryString: QueryString,
  navigator: PathNavigatorService,
  googleApiPlaces: new GoogleApiPlaces()
})

/**
 * @desc Service with async initialization, return unique merged and remapped object
 */
const asyncService$: Observable<AsyncServiceTypes> = forkJoin([
  i18n$.pipe(map((i18n: i18n) => ({ i18n: i18n }))),
  tagManager$.pipe(map((tagmanager: TagManagerInterface) => ({ tagmanager: tagmanager })))
]).pipe(concatAll())

/**
 * @desc subscribe to service observable, is necessary to wait i18n initilization
 * @desc for correct render/working of application and for get default app service in unique emission
 */
const service$ = concat(asyncService$, initialService$).pipe(
  // initialize group of service
  reduce((acc: ServiceProvidedInterface, value: DefaultServiceTypes | AsyncServiceTypes) => {
    return Object.assign(acc, value)
  }, {} as ServiceProvidedInterface)
)

/**
 * @desc default service of application is started
 */
service$.subscribe(
  (service: ServiceProvidedInterface) => {
    /**
     * @desc Main App Rendering, with Provider
     * @desc inside the provider, find the rootStores and main services
     * @return {ReactDOM}
     */
    ReactDOM.render(
      <Suspense fallback={<></>}>
        <CustomProvider service={service} globals={globals}></CustomProvider>
      </Suspense>,
      document.getElementById('shopping-cart-container')
    )
  },
  (err: Error) => {
    Sentry.captureException(err)
  },
  () => {
    if (isDev()) console.log('services initialzed')
  }
)
