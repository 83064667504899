import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
/**
 * @desc interface of shop for pick up (store)
 */
export interface ShopForPickUpInterface extends Instance<typeof ShopForPickUp> {}
export interface ShopForPickUpInterfaceSnapshotIn extends SnapshotIn<typeof ShopForPickUp> {}
export interface ShopForPickUpInterfaceSnapshotOut extends SnapshotOut<typeof ShopForPickUp> {}

const PaymentTypesModel = types.model('payments', {
  payment: types.string
})

export const ShopForPickUp = types
  .model('ShopForPickUp', {
    area: types.string,
    city: types.string,
    country: types.string,
    distance: types.number,
    email: types.optional(types.maybeNull(types.string), null),
    iplabsRetailerId: types.string,
    isInspirationPoint: types.boolean,
    isMailorder: types.boolean,
    isPickup: types.boolean,
    latitude: types.number,
    longitude: types.number,
    name: types.string,
    paymentTypes: types.array(PaymentTypesModel),
    phone: types.optional(types.maybeNull(types.string), null),
    shopCode: types.string,
    shopType: types.optional(types.maybeNull(types.string), null),
    street: types.string,
    zipCode: types.string
  })
  .actions((self): any => ({
    setPaymentsTypes(paymentTypes: Array<{ payment: string }>) {
      self.paymentTypes.clear()
      paymentTypes.forEach((paymentType) => {
        self.paymentTypes.push(paymentType)
      })
    }
  }))
