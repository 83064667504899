import { observable } from 'mobx'
import { inject, Observer } from 'mobx-react'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorGeneric from '../../../app/components/ErrorGeneric'
import { SpinnerComponentInterface } from '../../../app/components/Spinner'
import { TransNS } from '../../../app/components/TransNS'
import checkMarkIcon from '../../../assets/images/svg/check-mark3.svg'
import { FacadeApiClientInterface } from '../../api-service/FacadeApiClient'
import { COUPON_MAX_LENGTH } from '../../environment/const'
import { ShoppingCartHeaderApiResultInterface } from '../../model/api-result/ShoppingCartHeaderApiResultInterface'
import { GlobalsProvidedInterface, GlobalsResponsive } from '../../model/CustomGlobalsProvided'
import { ProviderServiceInterface } from '../../model/CustomServiceProvider'
import { CouponManagerServiceInterface } from '../../service/CouponManager'
import { ShoppingCartStoreInterface } from '../../state-manager/ShoppingCartStore'
import { ModalComponentInterface } from '../generic/Modal'
import AcceptedCoupon from './AcceptedCoupon'
import DeniedCoupon from './DeniedCoupon'

interface CouponCodeComponentInterface {
  readonly rootStore?: ShoppingCartStoreInterface
  readonly scApiClient?: FacadeApiClientInterface
  readonly globalModal$?: ModalComponentInterface
  readonly couponManager?: CouponManagerServiceInterface
  readonly globalSpinner$?: SpinnerComponentInterface
  readonly responsive?: GlobalsResponsive
}

export const couponCodeErrorMessage = observable({ error: '' })
const showCouponApplied = observable({ show: false })

export const CouponCodeComponent: React.FC<CouponCodeComponentInterface> = inject(
  (states: ProviderServiceInterface & GlobalsProvidedInterface) => ({
    rootStore: states.service.rootStore,
    scApiClient: states.service.scApiClient,
    couponManager: states.service.couponManager,
    globalModal$: states.globals.globalModal,
    globalSpinner$: states.globals.globalSpinner,
    responsive: states.globals.responsive
  })
)(({ rootStore, scApiClient, globalModal$, couponManager, globalSpinner$, responsive }) => {
  if (!rootStore || !scApiClient || !globalModal$ || !couponManager || !globalSpinner$) {
    const ErrorGeneric = React.lazy(() => import('../../../app/components/ErrorGeneric'))
    return <ErrorGeneric code={110010} i18nKeyMessage={'service_failed_injection'} />
  }

  const [couponCode, setCouponCode] = useState(rootStore.header.couponCode)
  const couponCodeInput = useRef<HTMLInputElement>(null)
  const container = useRef<HTMLDivElement>(null)
  const [showCouponNotValid, setShowCouponNotValid] = useState(false)

  const getCurrentCouponCode = (): string => {
    return couponCodeInput.current && couponCodeInput.current.value ? couponCodeInput.current.value : ''
  }

  const { t } = useTranslation('coupon')

  const noCouponCodeClass = 'noCouponCode'
  const couponCodeText = 'couponCodeTextExits'

  const onChangeCoupon = () => {
    if (!couponCodeInput || !couponCodeInput.current || !container || !container.current) {
      return
    }

    if (showCouponNotValid) {
      setShowCouponNotValid(false)
    }

    couponCodeInput.current.focus()
    if (couponCodeInput.current.value.length > 0) {
      if (couponCodeInput.current.parentElement) couponCodeInput.current.parentElement.classList.add(`${couponCodeText}`)
      setCouponCode(couponCodeInput.current.value)
      container.current.classList.remove(`${noCouponCodeClass}`)
    } else {
      resetCoupon()
    }
  }

  const resetCoupon = () => {
    if (!couponCodeInput || !couponCodeInput.current || !container || !container.current) {
      return
    }

    couponCodeInput.current.value = ''
    if (couponCodeInput.current.parentElement) couponCodeInput.current.parentElement.classList.remove(`${couponCodeText}`)
    container.current.classList.add(`${noCouponCodeClass}`)
    setCouponCode(getCurrentCouponCode())
    showCouponApplied.show = false
    setShowCouponNotValid(false)

    if (getCurrentCouponCode() !== rootStore.header.couponCode) {
      couponManager.removeCoupon$(rootStore.header.couponCode).subscribe(
        (header: ShoppingCartHeaderApiResultInterface) => {},
        (err: any) => {}
      )
    }
  }

  const onFocusCoupon = () => {
    if (!container || !container.current) {
      return
    }

    if (getCurrentCouponCode().length > 0) {
      container.current.classList.remove(`${noCouponCodeClass}`)
    }
  }

  const displayDeniedCoupon = (errorMessage: string) => {
    setShowCouponNotValid(true)
    showCouponApplied.show = false

    rootStore.deniedCoupon(errorMessage)
    globalSpinner$.visible = false
    // coupon rifiutato
    Object.assign(globalModal$, {
      component: (
        <DeniedCoupon
          deniedCode={getCurrentCouponCode()}
          onAction={() => {
            globalModal$.visible = false
          }}
        />
      ),
      dialogStyleOverride: { width: 'auto', height: 'auto', margin: 'auto' },
      hideClose: false,
      visible: true
    })
  }

  const onClickApply = () => {
    globalSpinner$.visible = true
    //check new coupon is the same of current?
    if (getCurrentCouponCode() === rootStore.header.couponCode) {
      globalSpinner$.visible = false
      Object.assign(globalModal$, {
        component: (
          <ErrorGeneric
            code={110042}
            level="warning"
            retry={false}
            title={t('coupon_is_applied_title')}
            subTitle={t('coupon_is_applied')}
            message="tried to re-apply the same coupon code"
          />
        ),
        dialogStyleOverride: { width: 'auto', height: 'auto', margin: 'auto' },
        hideClose: false,
        visible: true
      })
      return
    }

    couponManager.applyCoupon$(getCurrentCouponCode()).subscribe(
      (header: ShoppingCartHeaderApiResultInterface) => {
        if (!header.couponCode) {
          displayDeniedCoupon('Coupon not applied')
          return
        }

        showCouponApplied.show = true

        // Header has coupon
        globalSpinner$.visible = false
        //coupon accettato
        Object.assign(globalModal$, {
          component: <AcceptedCoupon onClose={() => (globalModal$.visible = false)} />,
          dialogStyleOverride: { width: 'auto', height: 'auto', margin: 'auto' },
          hideClose: false,
          visible: true
        })
      },
      (err: Error) => {
        displayDeniedCoupon(err.message)
      },
      () => {
        globalSpinner$.visible = false
      }
    )
  }

  const isCurrentCoupon = rootStore.header.couponCode === couponCode && couponCode !== ''

  if (responsive?.isDesktop) {
    return (
      <div id="coupon-code-input-container" ref={container} className={rootStore.header.couponCode.length <= 0 ? 'noCouponCode' : ''}>
        <div id="input-area" className={isCurrentCoupon ? 'isCurrentCoupon' : ''}>
          <input
            data-testid="couponCode"
            name="coupon-code"
            ref={couponCodeInput}
            defaultValue={couponCode}
            onChange={onChangeCoupon}
            onFocus={onFocusCoupon}
            placeholder={t('add_coupon_code')}
            // onInput={() => onClickApply()}
            maxLength={COUPON_MAX_LENGTH}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClickApply()
              }
            }}
          />
          {isCurrentCoupon ? (
            <button name="reset-coupon-code" onClick={resetCoupon}>
              ✕
            </button>
          ) : (
            <button name="apply-coupon-code" onClick={onClickApply}>
              <TransNS i18nKey="coupon:apply">Applica</TransNS>
            </button>
          )}
        </div>
        {showCouponNotValid && (
          <Observer>{() => <div className="coupon_code_message coupon_code_message__error">{couponCodeErrorMessage.error}</div>}</Observer>
        )}
        {showCouponApplied.show && (
          <div className="coupon_code_message coupon_code_message__applied">
            <img src={checkMarkIcon} alt="check mark" width="20" height="20" />
            <TransNS i18nKey="coupon:applied_coupon">Coupon applicato!</TransNS>
          </div>
        )}
      </div>
    )
  }

  return (
    <div id="coupon-code-input-container" ref={container} className={rootStore.header.couponCode.length <= 0 ? 'noCouponCode' : ''}>
      <div id="input-area" className={rootStore.header.couponCode === couponCode && couponCode !== '' ? 'isCurrentCoupon' : ''}>
        <input
          data-testid="couponCode"
          name="coupon-code"
          ref={couponCodeInput}
          defaultValue={couponCode}
          onChange={onChangeCoupon}
          onFocus={onFocusCoupon}
          placeholder={t('add_coupon_code_mobile')}
          // onInput={() => onClickApply()}
          maxLength={COUPON_MAX_LENGTH}
        />
        {isCurrentCoupon ? (
          <button name="reset-coupon-code" onClick={resetCoupon}>
            ✕
          </button>
        ) : (
          <button name="apply-coupon-code" onClick={onClickApply}>
            <TransNS i18nKey="coupon:apply">Applica</TransNS>
          </button>
        )}
      </div>
    </div>
  )
})
export default CouponCodeComponent
