/**
 * @desc this is the states of application, not the real cart status
 */
export const ShoppingCartStoreAcceptedStatusList = {
  not_initialized: 'not_initialized',
  loading: 'loading',
  ready: 'ready',
  error: 'error'
}
export type ShoppingCartStoreAcceptedStatus = 'not_initialized' | 'loading' | 'ready' | 'error'
