import React from 'react'
import { RouteProps } from 'react-router-dom'

interface RouteGuardInterface extends RouteProps {
  condition?: boolean
  deniedComponent?: object
  children?: any
}
const RouteGuard: React.FC<RouteGuardInterface> = ({ children, condition, deniedComponent }) => {
  if (typeof condition === 'undefined') {
    return <>{children}</>
  }

  if (condition === true) {
    return <>{children}</>
  } else {
    if (!deniedComponent) {
      return <></>
    }
    return <>{deniedComponent}</>
  }
}

export default RouteGuard
