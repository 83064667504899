import { ShoppingCartHeaderApiResultInterface } from '../model/api-result/ShoppingCartHeaderApiResultInterface'
import { ShoppingCartHeaderStoreInterfaceSnapshotOut } from '../state-manager/ShoppingCartHeaderStore'

/**
 * @desc adpter from cart header store to cart header api
 * @param cartItems
 */
export const HeaderApiAdapter = (header: ShoppingCartHeaderStoreInterfaceSnapshotOut): ShoppingCartHeaderApiResultInterface => {
  return {
    billingAddressArea: header.billingAddressArea,
    billingAddressCity: header.billingAddressCity,
    billingAddressCompanyName: header.billingAddressCompanyName,
    billingAddressCountry: header.billingAddressCountry,
    billingAddressEmail: header.billingAddressEmail,
    billingAddressFirstName: header.billingAddressFirstName,
    billingAddressId: header.billingAddressId,
    billingAddressLastName: header.billingAddressLastName,
    billingAddressNumber: header.billingAddressNumber,
    billingAddressPhone: header.billingAddressPhone,
    billingAddressStreet: header.billingAddressStreet,
    billingAddressZipCode: header.billingAddressZipCode,
    confirmationDate: header.confirmationDate,
    confirmed: header.confirmed,
    couponCode: header.couponCode,
    creationDate: header.creationDate,
    currencyCode: header.currencyCode,
    formattedVatIncludedCouponValue: header.formattedVatIncludedCouponValue,
    formattedVatIncludedFullTotal: header.formattedVatIncludedFullTotal,
    formattedVatIncludedPointsValue: header.formattedVatIncludedPointsValue,
    formattedVatIncludedShippingCost: header.formattedVatIncludedShippingCost,
    formattedVatIncludedSubTotal: header.formattedVatIncludedSubTotal,
    formattedVatIncludedTotal: header.formattedVatIncludedTotal,
    cumulativeCoupon: header.cumulativeCoupon,
    id: header.id,
    note: header.note,
    paid: header.paid,
    paymentType: header.paymentType,
    pd: header.pd,
    pointsSpent: header.pointsSpent,
    removedCouponCode: header.removedCouponCode,
    shippingAddressArea: header.shippingAddressArea,
    shippingAddressCity: header.shippingAddressCity,
    shippingAddressCompanyName: header.shippingAddressCompanyName,
    shippingAddressCountry: header.shippingAddressCountry,
    shippingAddressEmail: header.shippingAddressEmail,
    shippingAddressFirstName: header.shippingAddressFirstName,
    shippingAddressId: header.shippingAddressId,
    shippingAddressLastName: header.shippingAddressLastName,
    shippingAddressNumber: header.shippingAddressNumber,
    shippingAddressPhone: header.shippingAddressPhone,
    shippingAddressStreet: header.shippingAddressStreet,
    shippingAddressZipCode: header.shippingAddressZipCode,
    shippingType: header.shippingType,
    shopCode: header.shopCode,
    vatIncludedCouponValue: header.vatIncludedCouponValue,
    vatIncludedFullTotal: header.vatIncludedFullTotal,
    vatIncludedPointsValue: header.vatIncludedPointsValue,
    vatIncludedShippingCost: header.vatIncludedShippingCost,
    vatIncludedSubTotal: header.vatIncludedSubTotal,
    vatIncludedTotal: header.vatIncludedTotal,
    scalapayInstallment: header.scalapayInstallment
  }
}

export default HeaderApiAdapter
