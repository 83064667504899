import { ShoppingCartProductsApiResultInterface } from '../model/api-result/ShoppingCartProductsApiResultInterface'
import { ProductStoreInterface } from '../state-manager/ProductStore'

/**
 * @desc check options to find gift package
 * @param options
 */
const isGift = (options: Array<string>): boolean => {
  if (options && options.join(',').indexOf('PACKAGING_GIFTBOX') > -1) {
    return true
  }
  return false
}

/**
 * @desc trasform data of cart items from api in CartItems
 * @param cartItems
 */
export const ProductsStoreAdapter = (cartItems: ShoppingCartProductsApiResultInterface[]): ProductStoreInterface[] => {
  const shoppingCartProducts: ProductStoreInterface[] = []
  cartItems.forEach((item: ShoppingCartProductsApiResultInterface) => {
    const giftbox = isGift(item.options)
    const ProductsStoreAdapter = {
      id: item.id,
      itemId: item.itemId,
      itemVersionKey: item.itemVersionKey,
      price: item.vatIncludedTotalPrice,
      unitPrice: item.vatIncludedUnitaryFullPrice,
      formattedPrice: item.formattedVatIncludedTotalPrice,
      formattedPriceWithoutDiscount: item.formattedVatIncludedTotalFullPrice,
      discountPercentage: item.discountPercentage,
      formattedUnitPrice: item.formattedVatIncludedUnitaryFullPrice,
      currency: item.currencyCode,
      productCategoryCode: item.productCategoryCode || '',
      description: item.productCategoryName || '',
      title: item.productDisplayName || '',
      quantity: item.quantity,
      quantityEditable: item.quantityEditable || false,
      image: item.previewUrl || '',
      thumb: item.previewUrl || '',
      editUrl: item.editUrl,
      expirationDate: item.expirationDate,
      itemInfoUrl: item.itemInfoUrl,
      productCode: item.productCode,
      productType: item.productType || '',
      renewRetentionUrl: item.renewRetentionUrl || '',
      transactionId: item.transactionId || '',
      previewUrl: item.previewUrl,
      giftbox: giftbox || false,
      userConfigurationId: item.userConfigurationId
    }
    shoppingCartProducts.push(ProductsStoreAdapter)
  })
  return shoppingCartProducts
}
