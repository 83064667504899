import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface ShippingAddressStoreInterface extends Instance<typeof ShippingAddressStore> {}
export interface ShippingAddressStoreInterfaceSnapshotIn extends SnapshotIn<typeof ShippingAddressStore> {}
export interface ShippingAddressStoreInterfaceSnapshotOut extends SnapshotOut<typeof ShippingAddressStore> {}

export const ShippingAddressStore = types.model('ShippingAddressStore', {
  shippingType: types.string,
  deliveryTime: types.union(types.string, types.null)
})

export default ShippingAddressStore
