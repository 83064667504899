import PageScroll from './pageScroll'

export const globalSpinner = (target: HTMLElement | null) => {
  const globalSpinner = { visible: false }
  Object.defineProperty(globalSpinner, 'visible', {
    set: function (visible: boolean) {
      this.status = visible
      if (!target) return console.warn('global spinner not found')

      if (this.status === true) {
        PageScroll.lock()
        target.classList.remove('hide')
      } else {
        PageScroll.unlock()
        target.classList.add('hide')
      }
    },
    get: function (): boolean {
      return this.status || false
    }
  })
  return globalSpinner
}
