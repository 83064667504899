import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface UserTypesStoreInterface extends Instance<typeof UserTypesStore> {}
export interface UserTypesStoreInterfaceSnapshotIn extends SnapshotIn<typeof UserTypesStore> {}
export interface UserTypesStoreInterfaceSnapshotOut extends SnapshotOut<typeof UserTypesStore> {}

export const UserTypesStore = types
  .model('UserTypesStore', {
    id: types.number,
    types: types.array(types.string)
  })
  .actions((self: any): any => ({}))

export default UserTypesStore
