import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

/**
 * @desc interface of shopping cart
 */
export interface ShippingMethodStoreInterface extends Instance<typeof ShippingMethodStore> {}
export interface ShippingMethodStoreInterfaceSnapshotIn extends SnapshotIn<typeof ShippingMethodStore> {}
export interface ShippingMethodStoreInterfaceSnapshotOut extends SnapshotOut<typeof ShippingMethodStore> {}

export const ShippingMethodStore = types
  .model('ShippingMethodStore', {
    code: types.string,
    cost: types.number,
    currencyCode: types.string,
    description: types.optional(types.maybeNull(types.string), null),
    formattedCost: types.optional(types.maybeNull(types.string), null),
    name: types.optional(types.maybeNull(types.string), null),
    disabled: types.boolean,
    reason: types.optional(types.maybeNull(types.string), null)
  })
  .actions((self): any => ({
    set(method: ShippingMethodStoreInterface): void {
      self.code = method.code
      self.cost = method.cost
      self.currencyCode = method.currencyCode
      self.description = method.description
      self.formattedCost = method.formattedCost
      self.name = method.name
      self.disabled = method.disabled
      self.reason = method.reason
    }
  }))
