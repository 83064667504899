import { CONSUMER_USER } from '../environment/const'
import { ConsumerUserInfoApiResultInterface } from '../model/ConsumerUserInfoApiResultInterface'
import { ConsumerUserInfoStoreInterfaceSnapshotOut } from '../state-manager/ConsumerUserInfoStore'

export const ConsumerUserInfoApiAdapter = (data: ConsumerUserInfoApiResultInterface): ConsumerUserInfoStoreInterfaceSnapshotOut => {
  return {
    dateJoined: data.dateJoined,
    email: data.email,
    extraData: data.extraData,
    fullName: data.fullName,
    id: data.id,
    iplabsRetailerId: data.iplabsRetailerId,
    isActive: data.isActive,
    isStaff: data.isStaff,
    isValidProfile: data.isValidProfile,
    name: data.name,
    phoneNumber: data.phoneNumber,
    points: data.points || 0,
    retailer: data.retailer !== null ? data.retailer : null,
    shippingAddress: data.shippingAddress,
    shopCode: data.shopCode,
    social: data.social,
    surname: data.surname,
    taxcode: data.taxcode,
    types: [CONSUMER_USER]
  }
}
