import { ShippingMethodStoreInterfaceSnapshotOut } from '../state-manager/ShippingMethodStore'

export const ShippingMethodStoreMock: ShippingMethodStoreInterfaceSnapshotOut = {
  code: '',
  cost: 0,
  currencyCode: '',
  description: null,
  formattedCost: null,
  name: null,
  disabled: false,
  reason: null
}
