import { i18n } from 'i18next'
import { inject, Observer } from 'mobx-react'
import React from 'react'
import Picture from '../../../app/components/Picture'
import { TransNS } from '../../../app/components/TransNS'
import errorIcon from '../../../assets/images/svg/denied-coupon-error.svg'
import { ProviderServiceInterface } from '../../model/CustomServiceProvider'
import { CouponManagerServiceInterface } from '../../service/CouponManager'
import { ShoppingCartStoreInterface } from '../../state-manager/ShoppingCartStore'
import { couponCodeErrorMessage } from './CouponCode'

export interface DeniedCouponComponentInterface {
  readonly couponManager?: CouponManagerServiceInterface
  readonly rootStore?: ShoppingCartStoreInterface
  readonly i18n?: i18n
  deniedCode?: string
  onAction?: Function
}

export const DeniedCoupon: React.FC<DeniedCouponComponentInterface> = inject((states: ProviderServiceInterface) => ({
  rootStore: states.service.rootStore,
  couponManager: states.service.couponManager,
  i18n: states.service.i18n
}))(({ rootStore, i18n, deniedCode, couponManager, onAction }) => {
  if (!rootStore || !i18n || !couponManager) {
    const ErrorGeneric = React.lazy(() => import('../../../app/components/ErrorGeneric'))
    return <ErrorGeneric code={110011} i18nKeyMessage={'service_failed_injection'} />
  }

  const close = () => {
    if (typeof onAction === 'function') {
      onAction()
    }
  }

  return (
    <div className="denied-coupon tc">
      <Picture src={errorIcon} />
      <br />
      <h3>
        <TransNS i18nKey="denied-coupon:title">Ci dispiace!</TransNS>
        <br />
        <Observer>{() => <>{couponCodeErrorMessage.error}</>}</Observer>
      </h3>
      <br />
      <p>
        <TransNS i18nKey="denied-coupon:pls_verify_condition_of_promo">
          Verifica di averlo inserito correttamente e controlla se il tuo carrello soddisfa le condizioni della promozione.
        </TransNS>
      </p>

      <button className="phs-button secondary" onClick={() => close()}>
        <TransNS i18nKey="denied-coupon:close">Chiudi</TransNS>
      </button>
    </div>
  )
})

export default DeniedCoupon
