import { ShippingMethodApiResult } from '../model/api-result/ShippingMethodApiResult'
import { ShippingMethodModel } from '../model/ShippingMethod'

export const ShippingMethodAdapter = (method: ShippingMethodApiResult): ShippingMethodModel => {
  return {
    code: method.code,
    cost: method.cost,
    currencyCode: method.currencyCode,
    description: method.description,
    formattedCost: method.formattedCost,
    name: method.name,
    disabled: method.disabled,
    reason: method.reason
  }
}
