import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { UniqueRetailerDataInteface } from '../model/RetailerDataCookieInterface'
import { CartAcceptedStateInteface } from '../model/ShoppingCartAcceptedStatus'
import { DistributorStore } from './DistributorStore'
import { RetailerStore } from './RetailerStore'

export interface ProductsHeaderStoreInterface extends Instance<typeof ProductsHeaderStore> {}
export interface ProductsHeaderStoreInterfaceSnapshotIn extends SnapshotIn<typeof ProductsHeaderStore> {}
export interface ProductsHeaderStoreInterfaceSnapshotOut extends SnapshotOut<typeof ProductsHeaderStore> {}

export const ProductsHeaderStore = types
  .model('ProductsHeaderStore', {
    cartState: types.string,
    distributor: DistributorStore,
    retailer: RetailerStore,
    id: types.string,
    isIpLabsFlow: types.boolean,
    orderNumber: types.number,
    orderStatusUrl: types.string,
    orderUrl: types.string,
    expirationDate: types.string,
    selectedShopCode: types.string,
    selectedRetailerId: types.string
  })
  .actions((self: any): any => ({
    setCartState(state: CartAcceptedStateInteface) {
      self.cartState = state
    },
    getCartState(): CartAcceptedStateInteface {
      return self.cartState
    },
    setRetailer({ shopCode, siteDomain }: UniqueRetailerDataInteface) {
      self.retailer = RetailerStore.create({ shopCode: shopCode, url: siteDomain })
    }
  }))

export default ProductsHeaderStore
