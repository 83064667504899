import { useMediaQuery } from 'react-responsive'

export const unit_of_measure: string = 'px'
export const xxsmall: number = 0
export const xsmall: number = 360
export const small: number = 540
export const medium: number = 768
//export const large: number = 1366
export const large: number = 1280
export const xlarge: number = 1440
export const xxlarge: number = 1920

export class Breakpoints {
  static xxsmall(): boolean {
    return useMediaQuery({ maxWidth: xxsmall })
  }
  static xsmall(): boolean {
    return useMediaQuery({ maxWidth: xsmall })
  }
  static small(): boolean {
    return useMediaQuery({ maxWidth: small })
  }
  static medium(): boolean {
    return useMediaQuery({ maxWidth: medium })
  }
  static large(): boolean {
    return useMediaQuery({ maxWidth: large })
  }
  static xlarge(): boolean {
    return useMediaQuery({ maxWidth: xlarge })
  }
  static xxlarge(): boolean {
    return useMediaQuery({ minWidth: xxlarge })
  }
  static isPortrait(): boolean {
    return useMediaQuery({ orientation: 'portrait' })
  }
  static isRetina(): boolean {
    return useMediaQuery({ minResolution: '2ddpx' })
  }
  static isMobile(): boolean {
    return useMediaQuery({ maxWidth: medium })
  }
  static isDesktop(): boolean {
    return useMediaQuery({ minWidth: large })
  }
}

export const Desktop: React.FC<any> = ({ children }) => {
  return Breakpoints.isDesktop() && children ? children : null
}

export const Tablet: React.FC<any> = ({ children }) => {
  return Breakpoints.large() && children ? children : null
}

export const Mobile: React.FC<any> = ({ children }) => {
  return Breakpoints.isMobile() && children ? children : null
}
