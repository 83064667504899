import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface ConsumerUserInfoStoreInterface extends Instance<typeof ConsumerUserInfoStore> {}
export interface ConsumerUserInfoStoreInterfaceSnapshotIn extends SnapshotIn<typeof ConsumerUserInfoStore> {}
export interface ConsumerUserInfoStoreInterfaceSnapshotOut extends SnapshotOut<typeof ConsumerUserInfoStore> {}

export interface ConsumerUserInfoRetailStoreInterface extends Instance<typeof ConsumerUserInfoRetailStore> {}
export interface ConsumerUserInfoRetailStoreInterfaceSnapshotIn extends SnapshotIn<typeof ConsumerUserInfoRetailStore> {}
export interface ConsumerUserInfoRetailStoreInterfaceSnapshotOut extends SnapshotOut<typeof ConsumerUserInfoRetailStore> {}

export const ConsumerUserInfoRetailStore = types.model('ConsumerUserInfoRetailStore', {
  companyName: types.optional(types.maybeNull(types.string), null),
  id: types.number,
  iplabsRetailerId: types.number,
  shopCode: types.optional(types.maybeNull(types.string), null)
})

export const ConsumerUserInfoStore = types
  .model('ConsumerUserInfoStore', {
    dateJoined: types.optional(types.maybeNull(types.string), null),
    email: types.optional(types.maybeNull(types.string), null),
    extraData: types.optional(types.maybeNull(types.string), null),
    fullName: types.optional(types.maybeNull(types.string), null),
    id: types.number,
    iplabsRetailerId: types.optional(types.maybeNull(types.number), null),
    isActive: types.boolean,
    isStaff: types.boolean,
    isValidProfile: types.boolean,
    name: types.optional(types.maybeNull(types.string), null),
    phoneNumber: types.optional(types.maybeNull(types.string), null),
    points: types.number,
    retailer: types.optional(types.maybeNull(ConsumerUserInfoRetailStore), null),
    shippingAddress: types.optional(types.maybeNull(types.string), null),
    shopCode: types.optional(types.maybeNull(types.string), null),
    social: types.array(types.string),
    surname: types.optional(types.maybeNull(types.string), null),
    taxcode: types.optional(types.maybeNull(types.string), null),
    types: types.optional(types.maybeNull(types.array(types.string)), null)
  })
  .actions((self: any): any => ({}))

export default ConsumerUserInfoStore
