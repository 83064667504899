import { inject } from 'mobx-react'
import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProviderServiceInterface } from '../../domain/model/CustomServiceProvider'
export interface TransNSComponentInterface {
  i18nKey: string
}
export const TransNS: React.FC<TransNSComponentInterface> = inject((states: ProviderServiceInterface) => ({
  i18n: states.service.i18n
}))(({ i18nKey, children }) => {
  const [translate, setTranslate] = useState<string | null>(null)

  const { t } = useTranslation(i18nKey.split(':')[0])

  useLayoutEffect(() => {
    setTranslate(t(i18nKey.split(':')[1]))
  }, [])
  return <>{translate || children}</>
})
