import { UserInfoStoreInterfaceSnapshotOut } from '../state-manager/UserInfoStore'

export const UserInfoInitialStoreMock: UserInfoStoreInterfaceSnapshotOut = {
  email: '',
  fullName: '',
  id: 0,
  isActive: false,
  isStaff: false,
  name: '',
  surname: '',
  types: []
}
