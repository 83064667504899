import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface ConsumerUserMarketingDataStoreInterface extends Instance<typeof ConsumerUserMarketingDataStore> {}
export interface ConsumerUserMarketingDataStoreInterfaceSnapshotIn extends SnapshotIn<typeof ConsumerUserMarketingDataStore> {}
export interface ConsumerUserMarketingDataStoreInterfaceSnapshotOut extends SnapshotOut<typeof ConsumerUserMarketingDataStore> {}

export interface ConsumerUserInfoRetailStoreInterface extends Instance<typeof ConsumerUserInfoRetailStore> {}
export interface ConsumerUserInfoRetailStoreInterfaceSnapshotIn extends SnapshotIn<typeof ConsumerUserInfoRetailStore> {}
export interface ConsumerUserInfoRetailStoreInterfaceSnapshotOut extends SnapshotOut<typeof ConsumerUserInfoRetailStore> {}

export const ConsumerUserInfoRetailStore = types.model('ConsumerUserInfoRetailStore', {
  companyName: types.optional(types.maybeNull(types.string), null),
  id: types.number,
  iplabsRetailerId: types.number,
  shopCode: types.optional(types.maybeNull(types.string), null)
})

export const ConsumerUserMarketingDataStore = types
  .model('ConsumerUserMarketingDataStore', {
    hasAlreadyOrdered: types.boolean,
    id: types.number,
    lastLoginType: types.maybeNull(types.string),
    lastShippingType: types.maybeNull(types.string),
    lastShopCode: types.maybeNull(types.string),
    registrationDate: types.maybeNull(types.string)
  })
  .actions((self: any): any => ({}))

export default ConsumerUserMarketingDataStore
