import { ConsumerUserMarketingApiResultInterface } from '../model/ConsumerUserMarketingApiResultInterface'
import { ConsumerUserMarketingDataStoreInterfaceSnapshotOut } from '../state-manager/ConsumerUserMarketingDataStore'

export const ConsumerUserMarketingDataAdapter = (
  data: ConsumerUserMarketingApiResultInterface
): ConsumerUserMarketingDataStoreInterfaceSnapshotOut => {
  return {
    hasAlreadyOrdered: data.hasAlreadyOrdered,
    id: data.id,
    lastLoginType: data.lastLoginType,
    lastShippingType: data.lastShippingType,
    lastShopCode: data.lastShopCode,
    registrationDate: data.registrationDate
  }
}
