import { Observable } from 'rxjs'
import { bodyParam, XHROptions } from '../../../app/service/XHRRequest/XHRRequestObservable'
import ApiEnv from '../../environment/api-env'
import { ConsumerUserInfoApiResultInterface } from '../../model/ConsumerUserInfoApiResultInterface'
import { ConsumerUserMarketingApiResultInterface } from '../../model/ConsumerUserMarketingApiResultInterface'
import { ConsumerUserProfileApiResultInterface } from '../../model/ConsumerUserProfileApiResultInterface'
import { PutConsumerUserParams } from '../../model/PutConsumerUserParams'
import { RetailerUserInfoApiResultInterface } from '../../model/RetailerUserInfoApiResultInterface'
import { UserTypesApiResultInterface } from '../../model/UserTypesApiResultInterface'
import { AbstractApiClient } from '../AbstractApiClient'

export interface AccountsApiClientInterface {
  getConsumerUserInfo$(): Observable<ConsumerUserInfoApiResultInterface>
  getUserTypesInfo$(): Observable<UserTypesApiResultInterface>
  getRetailerUserInfo$(): Observable<RetailerUserInfoApiResultInterface>
  getConsumerUserMarketingData$(): Observable<ConsumerUserMarketingApiResultInterface>
  putConsumerUserProfile$(params: PutConsumerUserParams): Observable<ConsumerUserProfileApiResultInterface>
}

export class AccountsApiClient extends AbstractApiClient {
  constructor({ xhrRequestObv, querystring, guid }: any) {
    super(xhrRequestObv, querystring)
  }

  /**
   * @desc get user types
   */
  getUserTypesInfo$(): Observable<UserTypesApiResultInterface> {
    const params: XHROptions = {
      url: `${ApiEnv.userTypes.url}`
    }
    return this.get$(params)
  }

  /**
   * @desc get specific information of consumer user
   */
  getConsumerUserInfo$(): Observable<ConsumerUserInfoApiResultInterface> {
    const params: XHROptions = {
      url: `${ApiEnv.consumerUserData.url}`
    }
    return this.get$(params)
  }
  /**
   * @desc get specific information of consumer user
   */
  getConsumerUserMarketingData$(): Observable<ConsumerUserMarketingApiResultInterface> {
    const params: XHROptions = {
      url: `${ApiEnv.consumerUserMarketingData.url}`
    }
    return this.get$(params)
  }

  /**
   * @desc get specific information of retaielr user
   */
  getRetailerUserInfo$(): Observable<RetailerUserInfoApiResultInterface> {
    const params: XHROptions = {
      url: `${ApiEnv.retailerUserData.url}`
    }
    return this.get$(params)
  }

  /**
   * @desc put consumer user profile
   */
  putConsumerUserProfile$(data: PutConsumerUserParams): Observable<ConsumerUserProfileApiResultInterface> {
    const bodyParams: bodyParam[] = this.getBodyParams(data)
    const params: XHROptions = {
      method: 'PUT',
      body: bodyParams,
      url: `${ApiEnv.consumerUserProfile.url}`
    }
    return this.get$(params)
  }
}
