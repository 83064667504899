import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface AreaStoreInterface extends Instance<typeof AreaStore> {}
export interface AreaStoreInterfaceSnapshotIn extends SnapshotIn<typeof AreaStore> {}
export interface AreaStoreInterfaceSnapshotOut extends SnapshotOut<typeof AreaStore> {}

export const AreaStore = types.model('AreaStore', {
  code: types.string,
  id: types.number,
  name: types.string
})
