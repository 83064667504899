// TRANSLATION
import i18next from 'i18next'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { Observable, Observer } from 'rxjs'
import { CACHE_VERSION, I18N_PATH, SUPPORTED_LANGUAGES } from '../environment/const'
import { getLanguage, isDev } from '../helpers/utils'

/**
 * @desc return an observable, this emit for first time the i18n service and complete
 */
export const i18n$ = new Observable((observer: Observer<any>) => {
  let language = getLanguage()

  if (!SUPPORTED_LANGUAGES.includes(language)) language = 'en'

  /**
   * i18n translations initialize
   */
  i18next
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      keySeparator: false,
      interpolation: {
        escapeValue: false
      },
      debug: isDev(),
      lng: language,
      preload: [language],
      fallbackLng: false,
      ns: ['translation'],
      defaultNS: 'translation',
      backend: {
        loadPath: `${I18N_PATH}/{{lng}}/{{ns}}.json`,
        allowMultiLoading: false,
        queryStringParams: { v: CACHE_VERSION },
        requestOptions: {
          cache: 'default'
        }
      },
      load: 'languageOnly',
      react: {
        wait: true,
        useSuspense: true
      }
    })

  i18next.on('initialized', (options: any) => {
    observer.next(i18next)
    observer.complete()
  })

  i18next.on('failedLoading', (err: Error) => {
    observer.error(err)
  })
})

export default i18n$
