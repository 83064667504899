import { ShoppingCartHeaderStoreInterfaceSnapshotOut } from '../state-manager/ShoppingCartHeaderStore'

export const ShoppingCartHeaderStoreMock: ShoppingCartHeaderStoreInterfaceSnapshotOut = {
  billingAddressArea: '',
  billingAddressCity: '',
  billingAddressCompanyName: '',
  billingAddressCountry: '',
  billingAddressEmail: '',
  billingAddressFirstName: '',
  billingAddressId: '',
  billingAddressLastName: '',
  billingAddressNumber: '',
  billingAddressPhone: '',
  billingAddressStreet: '',
  billingAddressZipCode: '',
  confirmationDate: '',
  confirmed: false,
  couponCode: '',
  creationDate: '',
  currencyCode: '',
  cumulativeCoupon: false,
  formattedVatIncludedCouponValue: '',
  formattedVatIncludedFullTotal: '',
  formattedVatIncludedPointsValue: '',
  formattedVatIncludedShippingCost: '',
  formattedVatIncludedSubTotal: '',
  formattedVatIncludedTotal: '',
  id: '',
  note: '',
  paid: false,
  paymentType: '',
  pd: '',
  pointsSpent: 0,
  removedCouponCode: '',
  shippingAddressArea: '',
  shippingAddressCity: '',
  shippingAddressCompanyName: '',
  shippingAddressCountry: '',
  shippingAddressEmail: '',
  shippingAddressFirstName: '',
  shippingAddressId: '',
  shippingAddressLastName: '',
  shippingAddressNumber: '',
  shippingAddressPhone: '',
  shippingAddressStreet: '',
  shippingAddressZipCode: '',
  shippingType: '',
  shopCode: '',
  vatIncludedCouponValue: 0,
  vatIncludedFullTotal: 0,
  vatIncludedPointsValue: 0,
  vatIncludedShippingCost: 0,
  vatIncludedSubTotal: 0,
  vatIncludedTotal: 0,
  scalapayInstallment: 0
}
