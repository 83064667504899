import {
  acceptedShippingMethodNames,
  SHIPPING,
  SHIPPING_METHOD_EXPRESS,
  SHIPPING_METHOD_MAILORDER,
  SHIPPING_METHOD_PICKUP
} from '../../../environment/const'
import { ShippingMethodApiResult } from '../../../model/api-result/ShippingMethodApiResult'
import { ShippingMethodModel } from '../../../model/ShippingMethod'
import { ShippingMethodData, ShippingMethodOutput } from '../../../model/ShippingMethodOutput'
import { ShippingMethodServiceInterface } from '../../../service/ShippingMethodService'
import { ShoppingCartStoreInterface } from '../../../state-manager/ShoppingCartStore'

export const shippingMethodDestination = (code: acceptedShippingMethodNames) => {
  const pathHistoryLocalized: Array<string> = [SHIPPING]
  switch (code) {
    default:
    case SHIPPING_METHOD_PICKUP:
      pathHistoryLocalized.push('shipping_pick_up_store')
      break
    case SHIPPING_METHOD_MAILORDER:
      pathHistoryLocalized.push('shipping_mail_order')
      break
    case SHIPPING_METHOD_EXPRESS:
      pathHistoryLocalized.push('shipping_mail_order')
      break
  }

  return pathHistoryLocalized
}

export const enrichShippingMethod = (
  method: ShippingMethodModel,
  shippingMethodService: ShippingMethodServiceInterface
): ShippingMethodData | void => {
  const { code } = method
  const outputLocalized: ShippingMethodOutput | void = shippingMethodService.enrichMethod(code)
  if (outputLocalized) {
    return {
      localizedData: outputLocalized,
      method
    }
  }
}

export const setShippingMethod = (method: ShippingMethodApiResult, rootStore: ShoppingCartStoreInterface) => {
  rootStore.setShippingMethod(method)
}
