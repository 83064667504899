import { Observable } from 'rxjs'
import { bodyParam, XHROptions } from '../../../app/service/XHRRequest/XHRRequestObservable'
import ApiEnv from '../../environment/api-env'
import { ShippingAddressApiResultInterface } from '../../model/api-result/ShippingAddressApiResult'
import { ShippingMethodApiResult } from '../../model/api-result/ShippingMethodApiResult'
import { AbstractApiClient } from '../AbstractApiClient'

export interface ConsumerShippingAddressesApiClientInterface {
  getShippingAddresses$(): Observable<ShippingAddressApiResultInterface[]>
  putShippingAddress$(id: string, body: bodyParam[]): Observable<ShippingMethodApiResult>
  patchShippingAddress$(id: string, body: bodyParam[]): Observable<ShippingMethodApiResult>
  deleteShippingAddress$(id: string): Observable<ShippingMethodApiResult>
  postShippingAddress$(body: bodyParam[]): Observable<ShippingMethodApiResult>
}

export class ConsumerShippingAddressesApiClient extends AbstractApiClient {
  private guid: string = ''
  constructor({ xhrRequestObv, querystring, guid }: any) {
    super(xhrRequestObv, querystring)
    this.guid = guid
  }

  /**
   * @desc get list of address of user
   * @return {Observable}
   */
  getShippingAddresses$(): Observable<ShippingAddressApiResultInterface[]> {
    const params: XHROptions = {
      url: `${ApiEnv.shippingAddresses.url}`
    }
    return this.get$(params)
  }

  /**
   * @desc update shipping address
   * @param id
   * @param body
   * @return {Observable}
   */
  putShippingAddress$(id: string, body: bodyParam[]): Observable<ShippingMethodApiResult> {
    const url = `${ApiEnv.shippingAddresses.url}`
    const params: XHROptions = {
      method: 'PUT',
      body: body,
      url: `${url}${id}`
    }
    return this.get$(params)
  }

  /**
   * @desc update shipping address default properties
   * @param id
   * @param body
   * @return {Observable}
   */
  patchShippingAddress$(id: string, body: bodyParam[]): Observable<ShippingMethodApiResult> {
    const url = `${ApiEnv.shippingAddresses.url}`
    body.push({ key: 'id', value: id })
    const params: XHROptions = {
      method: 'PATCH',
      body: body,
      url: `${url}${id}`
    }

    return this.get$(params)
  }

  /**
   * @desc delete shipping address
   * @param id
   * @param body
   * @return {Observable}
   */
  deleteShippingAddress$(id: string): Observable<ShippingMethodApiResult> {
    const url = `${ApiEnv.shippingAddresses.url}`
    const params: XHROptions = {
      method: 'DELETE',
      url: url + `${id}`
    }

    return this.get$(params)
  }

  /**
   * @desc add shipping address
   * @param id
   * @param body
   * @return {Observable}
   */
  postShippingAddress$(body: bodyParam[]): Observable<ShippingMethodApiResult> {
    const url = `${ApiEnv.shippingAddresses.url}`
    const params: XHROptions = {
      method: 'POST',
      body: body,
      url: url.replace('{id}', this.guid)
    }

    return this.get$(params)
  }
}
