import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface UserInfoStoreInterface extends Instance<typeof UserInfoStore> {}
export interface UserInfoStoreInterfaceSnapshotIn extends SnapshotIn<typeof UserInfoStore> {}
export interface UserInfoStoreInterfaceSnapshotOut extends SnapshotOut<typeof UserInfoStore> {}

export const UserInfoStore = types
  .model('UserInfoStore', {
    email: types.string,
    fullName: types.string,
    id: types.number,
    isActive: types.boolean,
    isStaff: types.boolean,
    name: types.string,
    surname: types.string,
    types: types.array(types.string)
  })
  .actions((self: any): any => ({}))

export default UserInfoStore
