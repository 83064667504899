import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface PaymentMethodStoreInterface extends Instance<typeof PaymentMethodStore> {}
export interface PaymentMethodStoreInterfaceSnapshotIn extends SnapshotIn<typeof PaymentMethodStore> {}
export interface PaymentMethodStoreInterfaceSnapshotOut extends SnapshotOut<typeof PaymentMethodStore> {}

export const PaymentMethodStore = types
  .model('PaymentMethodStore', {
    code: types.string,
    integrationType: types.string,
    method: types.string
  })
  .actions((self: any): any => ({
    setCode(code: string) {
      self.code = code
    },
    setIntegrationType(integrationType: string) {
      self.integrationType = integrationType
    },
    setMethod(method: string) {
      self.method = method
    }
  }))

export default PaymentMethodStore
