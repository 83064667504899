import { getSnapshot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { ShippingAddressFormInterface } from '../model/ShippingAddressForm'
import { ShippingAddressStore, ShippingAddressStoreInterface } from './ShippingAddress'

/**
 * @desc interface of single shipping address
 */
export interface ShippingAddressesStoreInterface extends Instance<typeof ShippingAddressesStore> {}
export interface ShippingAddressesStoreInterfaceSnapshotIn extends SnapshotIn<typeof ShippingAddressesStore> {}
export interface ShippingAddressesStoreInterfaceSnapshotOut extends SnapshotOut<typeof ShippingAddressesStore> {}

export const ShippingAddressesStore = types
  .model('ShippingAddressesStore', {
    shippingAddresses: types.array(ShippingAddressStore)
  })
  .actions((self): any => ({
    setAddresses(addresses: ShippingAddressesStoreInterface[]) {
      self.shippingAddresses.clear()
      addresses.forEach((address: ShippingAddressStoreInterface) => {
        self.shippingAddresses.push(address)
      })
    },
    setDefaultAddress(id: string) {
      self.shippingAddresses.forEach((address: ShippingAddressStoreInterface) => {
        address.setDefualt(address.id === id ? true : false)
      })
    },
    updateAddressById(id: string, values: ShippingAddressFormInterface): void {
      self.shippingAddresses.forEach((address: ShippingAddressStoreInterface) => {
        if (address.id === id) {
          address.setFirstName(values.firstname)
          address.setLastName(values.lastname)
          address.setStreet(values.street)
          address.setZipCode(values.zipcode)
          address.setCity(values.city)
          address.setArea(values.province)
          address.setCountry(values.country)
          address.setPhone(values.phone)
          address.setAdditionInfo(values.additionalinfo)
          address.setCareOf(values.careof)
          address.setNumber(values.number)
          address.setLatLng(values.lat, values.lng)
        }
      })
    },
    removeById(id: string) {
      const condition = (address: ShippingAddressStoreInterface) => {
        return id != address.id
      }
      const filterdAddress = getSnapshot(self.shippingAddresses)
      self.shippingAddresses.clear()
      filterdAddress.forEach((address: ShippingAddressStoreInterface) => {
        if (condition(address)) {
          self.shippingAddresses.push(address)
        }
      })
    },
    getDefaultAddress(): ShippingAddressStoreInterface | void {
      const addresses = getSnapshot<ShippingAddressStoreInterface[]>(self.shippingAddresses)
      const reducer = (accumulator: ShippingAddressStoreInterface, address: ShippingAddressStoreInterface) => {
        if (address.default === true) {
          accumulator = address
        }
        return accumulator
      }
      if (addresses.length <= 0) {
        return
      }
      return addresses.reduce(reducer)
    },
    getDefaultById(id: string): ShippingAddressStoreInterface | void {
      const addresses = getSnapshot<ShippingAddressStoreInterface[]>(self.shippingAddresses)
      const reducer = (accumulator: ShippingAddressStoreInterface, address: ShippingAddressStoreInterface) => {
        if (address.id === id) {
          accumulator = address
        }
        return accumulator
      }
      if (addresses.length <= 0) {
        return
      }
      return addresses.reduce(reducer)
    }
  }))
