import { PaymentTypes, ShopManagerApiResultInterface } from '../model/api-result/ShopManagerApiResult'
import { ShopManagerInterface } from '../model/ShopManagerModel'
import { ShopForPickUpInterface } from '../state-manager/ShopForPickUp'

export const ShopAdapterToStore = (shop: ShopManagerApiResultInterface): ShopForPickUpInterface => {
  const paymentTypes: Array<any> = []

  shop.paymentTypes.forEach((payment: PaymentTypes) => {
    paymentTypes.push({ payment: payment })
  })

  return Object.assign(
    {},
    {
      area: shop.area,
      city: shop.city,
      country: shop.country,
      distance: shop.distance ? shop.distance : 0,
      email: shop.email,
      iplabsRetailerId: shop.iplabsRetailerId,
      isInspirationPoint: shop.isInspirationPoint,
      isMailorder: shop.isMailorder,
      isPickup: shop.isPickup,
      latitude: shop.latitude,
      longitude: shop.longitude,
      name: shop.name,
      paymentTypes: paymentTypes,
      phone: shop.phone,
      shopCode: shop.shopCode,
      shopType: shop.shopType,
      street: shop.street,
      zipCode: shop.zipCode
    }
  )
}

export const ShopManagerApiAdapter = (shop: ShopManagerApiResultInterface): ShopManagerInterface => {
  return Object.assign(
    {},
    {
      area: shop.area,
      city: shop.city,
      country: shop.country,
      distance: shop.distance ? shop.distance : 0,
      email: shop.email,
      iplabsRetailerId: shop.iplabsRetailerId,
      isInspirationPoint: shop.isInspirationPoint,
      isMailorder: shop.isMailorder,
      isPickup: shop.isPickup,
      latitude: shop.latitude,
      longitude: shop.longitude,
      name: shop.name,
      paymentTypes: shop.paymentTypes,
      phone: shop.phone,
      shopCode: shop.shopCode,
      shopType: shop.shopType,
      street: shop.street,
      zipCode: shop.zipCode
    }
  )
}

export default { ShopAdapterToStore, ShopManagerApiAdapter }
