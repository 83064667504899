export interface StorageWrapperInterface {
  persist(key: string, data: any): void
  load(key: string): string
  remove(key: string): void
}
export class StorageWrapper implements StorageWrapperInterface {
  constructor(private storage: any = window.localStorage) {}
  persist(key: string, data: any) {
    this.storage.setItem(key, data)
  }
  load(key: string) {
    return this.storage.getItem(key)
  }
  remove(key: string) {
    this.storage.removeItem(key)
  }
}
