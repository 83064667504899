import { QueryStringParamsAcceptedStoreInterfaceSnapshotOut } from '../state-manager/QueryStringParamsAcceptedStore'

export const QueryStringParamsAcceptedStoreMock: QueryStringParamsAcceptedStoreInterfaceSnapshotOut = {
  guid: null,
  message: null,
  nextUrl: null,
  token: null,
  productsAdded: null,
  softwareCode: null,
  softwareVersion: null,
  status: null
}

export default QueryStringParamsAcceptedStoreMock
