import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface RetailerStoreInterface extends Instance<typeof RetailerStore> {}
export interface RetailerStoreInterfaceSnapshotIn extends SnapshotIn<typeof RetailerStore> {}
export interface RetailerStoreInterfaceSnapshotOut extends SnapshotOut<typeof RetailerStore> {}

export const RetailerStore = types.model('RetailerStore', {
  shopCode: types.string,
  url: types.string
})
