import { QueryStringParamsAccepted } from '../../app/service/queryString/queryStringService'

/**
 * @interface
 * @desc interface with accepted payload method for api client
 */
export interface FactorySCApiPayloadInterface {
  getGuid(): string | undefined
  getToken(): string | undefined
  getSoftwareCode(): string | undefined
  getSoftwareVersion(): string | undefined
}

/**
 * @class
 * @desc factory of shopping cart api client payload
 */
export class FactorySCApiPayload implements FactorySCApiPayloadInterface {
  constructor(private params: QueryStringParamsAccepted) {}
  /**
   * @method
   * @desc return the guid of shopping cart
   */
  getGuid(): string | undefined {
    const { guid } = this.params
    return guid
  }
  getToken(): string | undefined {
    const { token } = this.params
    return token
  }
  getSoftwareCode(): string | undefined {
    const { softwareCode } = this.params
    return softwareCode
  }
  getSoftwareVersion(): string | undefined {
    const { softwareVersion } = this.params
    return softwareVersion
  }
}
export default FactorySCApiPayload
