import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface QueryStringParamsAcceptedStoreInterface extends Instance<typeof QueryStringParamsAcceptedStore> {}
export interface QueryStringParamsAcceptedStoreInterfaceSnapshotIn extends SnapshotIn<typeof QueryStringParamsAcceptedStore> {}
export interface QueryStringParamsAcceptedStoreInterfaceSnapshotOut extends SnapshotOut<typeof QueryStringParamsAcceptedStore> {}

export const QueryStringParamsAcceptedStore = types
  .model('QueryStringParamsAcceptedStore', {
    guid: types.optional(types.maybeNull(types.string), null),
    token: types.optional(types.maybeNull(types.string), null),
    status: types.optional(types.maybeNull(types.string), null),
    message: types.optional(types.maybeNull(types.string), null),
    nextUrl: types.optional(types.maybeNull(types.string), null),
    softwareCode: types.optional(types.maybeNull(types.string), null),
    softwareVersion: types.optional(types.maybeNull(types.string), null),
    productsAdded: types.optional(types.maybeNull(types.string), null)
  })
  .actions((self: any): any => ({}))

export default QueryStringParamsAcceptedStore
