import { AreasApiResult } from '../model/GeographicsInterface'
import { AreaStoreInterface } from '../state-manager/AreaStore'

export const AreasAdapter = (areas: AreasApiResult[]): AreaStoreInterface[] => {
  return areas.map((areas: AreasApiResult) => AreaAdapter(areas))
}

export const AreaAdapter = (area: AreasApiResult): AreaStoreInterface => {
  return {
    code: area.code,
    id: area.id,
    name: area.name
  }
}
