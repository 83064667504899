import { Observable } from 'rxjs'
import { XHROptions } from '../../../app/service/XHRRequest/XHRRequestObservable'
import ApiEnv from '../../environment/api-env'
import { AbstractApiClient } from '../AbstractApiClient'

export interface PromotionsConsumerApiClientInterface {
  getCouponCode$(campaignCode: string, userId: number): Observable<any>
}

export class PromotionsConsumerApiClient extends AbstractApiClient {
  constructor({ xhrRequestObv, querystring }: any) {
    super(xhrRequestObv, querystring)
  }

  /**
   * @desc get coupon code
   */
  getCouponCode$(campaignCode: string, userId: number): Observable<any> {
    const params: XHROptions = {
      url: `${ApiEnv.couponCode.url}?campaignCode=${campaignCode}&userId=${userId}`
    }
    return this.get$(params)
  }
}
