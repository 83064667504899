import styled from 'styled-components'

type ButtonProps = {
  primary?: boolean
  block?: boolean
  disabled?: boolean
}
export const Button = styled.button<ButtonProps>`
  border-radius: 32px;
  font-weight: bold;
  padding: 1rem;
  outline: none;

  width: ${(props) => (props.block ? '100%' : 'auto')};
  color: ${({ primary, disabled }) => (primary ? '#fff' : disabled ? '#ccc' : '#00A2AB')};
  background-color: ${({ primary, disabled }) => (!primary ? '#fff' : disabled ? '#ccc' : '#00A2AB')};
  border: 1px solid ${({ disabled }) => (disabled ? '#ccc' : '#00A2AB')};
  margin-top: 0.5rem;
`
