import { ProductsHeaderStoreInterfaceSnapshotOut } from '../state-manager/ProductsHeaderStore'

export const ShoppingCartProductsHeaderStoreMock: ProductsHeaderStoreInterfaceSnapshotOut = {
  cartState: '',
  distributor: {
    express: false,
    code: '',
    configurationCode: '',
    mailOrder: false,
    opId: 0,
    pickUpStore: false,
    url: '',
    mailOrderShopCode: ''
  },
  retailer: {
    shopCode: '',
    url: ''
  },
  id: '',
  isIpLabsFlow: false,
  orderNumber: 0,
  orderStatusUrl: '',
  orderUrl: '',
  expirationDate: '',
  selectedShopCode: '',
  selectedRetailerId: ''
}
