import { cast, getSnapshot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import {
  acceptedPaymentType,
  CONSUMER_USER,
  RETAILER_USER,
  SHIPPING_METHOD_EXPRESS,
  SHIPPING_METHOD_MAILORDER,
  SHIPPING_METHOD_PICKUP,
  userTypes
} from '../environment/const'
import { replaceWithStringTheNullInObject } from '../helpers/utils'
import { PaymentTypes } from '../model/api-result/ShopManagerApiResult'
import { PaymentMethodInterface } from '../model/PaymentMethodInterface'
import { ProductUpdateQuantityInterface } from '../model/ProductUpdateQuantityInterface'
import { CartAcceptedStateInteface } from '../model/ShoppingCartAcceptedStatus'
import { ShoppingCartStoreAcceptedStatus, ShoppingCartStoreAcceptedStatusList } from '../model/ShoppingCartStoreAcceptedStatus'
import ConsumerUserInfoStore, { ConsumerUserInfoStoreInterfaceSnapshotIn } from './ConsumerUserInfoStore'
import ConsumerUserMarketingDataStore, { ConsumerUserMarketingDataStoreInterfaceSnapshotIn } from './ConsumerUserMarketingDataStore'
import { DistributorStoreInterfaceSnapshotOut } from './DistributorStore'
import OrderStore, { OrderStoreInterfaceSnapshotOut } from './OrderStore'
import PaymentMethodStore, { PaymentMethodStoreInterfaceSnapshotOut } from './PaymentMethodStore'
import { ProductsHeaderStore, ProductsHeaderStoreInterface } from './ProductsHeaderStore'
import { ProductStore, ProductStoreInterface, ProductStoreInterfaceSnapshotOut } from './ProductStore'
import QueryStringParamsAcceptedStore, { QueryStringParamsAcceptedStoreInterfaceSnapshotIn } from './QueryStringParamsAcceptedStore'
import RetailerUserInfoStore, { RetailerUserInfoStoreInterfaceSnapshotIn } from './RetailerUserInfoStore'
import { ShippingAddressStoreInterfaceSnapshotOut } from './ShippingAddress'
import { ShippingAddressesStore, ShippingAddressesStoreInterface } from './ShippingAddresses'
import { ShippingDataForRetailerStore } from './ShippingDataForRetailer'
import ShippingDateStore, { ShippingAddressStoreInterfaceSnapshotIn } from './ShippingDateStore'
import {
  ShippingMethodStore,
  ShippingMethodStoreInterface,
  ShippingMethodStoreInterfaceSnapshotIn,
  ShippingMethodStoreInterfaceSnapshotOut
} from './ShippingMethodStore'
import { ShopForPickUp, ShopForPickUpInterface } from './ShopForPickUp'
import ShoppingCartHeaderStore, { ShoppingCartHeaderStoreInterface } from './ShoppingCartHeaderStore'
import UserInfoStore, { UserInfoStoreInterfaceSnapshotIn } from './UserInfoStore'
import UserTypesStore, { UserTypesStoreInterfaceSnapshotIn } from './UserTypesStore'

/**
 * @desc interface of shopping cart (ROOTSTORE)
 */
export interface ShoppingCartStoreInterface extends Instance<typeof ShoppingCartStore> {}
export interface ShoppingCartStoreInterfaceSnapshotIn extends SnapshotIn<typeof ShoppingCartStore> {}
export interface ShoppingCartStoreInterfaceSnapshotOut extends SnapshotOut<typeof ShoppingCartStore> {}

/*
  Questo è il ROOT STORE
*/
export const ShoppingCartStore = types
  .model('ShoppingCartStore', {
    products: types.array(ProductStore),
    productsHeader: ProductsHeaderStore,
    header: ShoppingCartHeaderStore,
    order: OrderStore,
    status: types.string,
    progress: types.number,
    errorMessage: types.string,
    shippingMethod: ShippingMethodStore,
    shippingAddresses: ShippingAddressesStore,
    shopForPickUp: ShopForPickUp,
    paymentMethod: PaymentMethodStore,
    userInfo: UserInfoStore,
    consumerUserInfo: ConsumerUserInfoStore,
    consumerUserMarketingData: ConsumerUserMarketingDataStore,
    retailerUserInfo: RetailerUserInfoStore,
    userType: types.string,
    userTypes: UserTypesStore,
    lastCoupon: types.string,
    shippingDataForRetailer: ShippingDataForRetailerStore,
    createUserToken: types.boolean,
    queryStringParamsAccepted: QueryStringParamsAcceptedStore,
    shippingMethodList: types.array(ShippingMethodStore),
    shippingDates: types.array(ShippingDateStore)
  })
  .actions((self): any => ({
    setCreateUserToken(value: boolean) {
      self.createUserToken = value
    },
    setStatus(status: ShoppingCartStoreAcceptedStatus): void {
      self.status = status
    },

    setQueryStringParamsAccepted(paramsAccepted: QueryStringParamsAcceptedStoreInterfaceSnapshotIn): void {
      self.queryStringParamsAccepted = paramsAccepted
    },

    setProducts(products: ProductStoreInterface[]) {
      self.products.clear()
      products.forEach((product: ProductStoreInterface) => {
        self.products.push(product)
      })
    },
    setProductsHeader(productsHeader: ProductsHeaderStoreInterface): void {
      self.productsHeader = productsHeader
    },
    setHeader(header: ShoppingCartHeaderStoreInterface): void {
      self.header = header
    },
    setHeaderPickUpStore(header: ShoppingCartHeaderStoreInterface): void {
      self.header = replaceWithStringTheNullInObject(header)
    },
    setHeaderMailOrder(header: ShoppingCartHeaderStoreInterface): void {
      self.header = header
    },
    setErrorMessage(message: string): void {
      self.errorMessage = message
    },
    getErrorMessage(): string {
      return self.errorMessage
    },
    setShippingMethod(method: ShippingMethodStoreInterfaceSnapshotOut) {
      self.shippingMethod.set(method)
    },
    setProgress(progress: number) {
      self.progress = progress
    },
    setShippingAddress(address: ShippingAddressesStoreInterface) {
      self.shippingAddresses = address
    },
    setShopForPickUp(shop: ShopForPickUpInterface) {
      self.shopForPickUp = shop
    },
    setPaymentMethod(paymentMethod: PaymentMethodInterface) {
      self.paymentMethod = paymentMethod
    },
    setPaymentType(paymentType: acceptedPaymentType) {
      self.header.paymentType = paymentType
    },
    shippingAddressesQty(): number {
      return self.shippingAddresses.shippingAddresses.length || 0
    },
    shippingMethodCode(): string {
      return self.shippingMethod.code
    },
    setCartState(state: CartAcceptedStateInteface) {
      self.productsHeader.cartState = state
    },
    cartHeaderStatus(): string {
      return self.productsHeader.cartState
    },
    productRowsQty(): number {
      return self.products.length || 0
    },
    setUserInfo(userInfo: UserInfoStoreInterfaceSnapshotIn) {
      self.userInfo = userInfo
    },
    setConsumerUserInfo(consumerUserInfo: ConsumerUserInfoStoreInterfaceSnapshotIn) {
      self.consumerUserInfo = consumerUserInfo
    },
    setConsumerUserMarketingData(data: ConsumerUserMarketingDataStoreInterfaceSnapshotIn) {
      self.consumerUserMarketingData = data
    },
    setRetailerUserInfo(retailerUserInfo: RetailerUserInfoStoreInterfaceSnapshotIn) {
      self.retailerUserInfo = retailerUserInfo
    },
    setUserType(userType: userTypes) {
      self.userType = userType
    },
    setUserTypes(userTypes: UserTypesStoreInterfaceSnapshotIn) {
      self.userTypes = userTypes
    },
    // SPECIALIZED GETTERS
    getProductsQty(): number {
      let qta = 0
      self.products.forEach((product: ProductStoreInterface) => {
        qta += product.quantity
      })
      return qta
    },
    shoppintCartsIsConfirmed(): boolean {
      return self.header.confirmed
    },
    getHeaderId(): string {
      return self.header.id
    },
    getShippingType(): string | null {
      return self.header.shippingType
    },
    setShippingMethods(shippingMethods: ShippingMethodStoreInterfaceSnapshotIn[]) {
      self.shippingMethodList = cast(shippingMethods)
    },
    getProducts(): Array<ProductStoreInterface> {
      return getSnapshot(self.products)
    },
    isLastCustomProductInCart(productId: string): boolean {
      const products = getSnapshot(self.products)
      const customProducts = products.filter((product) => product.userConfigurationId == null)

      if (customProducts.length != 1) return false

      return customProducts[0].id == productId
    },
    getShippingMethods(): Array<ShippingMethodStoreInterface> {
      return self.shippingMethodList
    },
    getUserShopCode(): string | null {
      switch (self.userType) {
        case RETAILER_USER:
          return self.retailerUserInfo.shopCode
          break
        default:
        case CONSUMER_USER:
          return self.consumerUserInfo.shopCode
          break
      }
    },
    getShopCode(): string | null {
      return self.header.shopCode
    },
    getGuid(): string {
      return self.productsHeader.id || ''
    },
    setOrder(order: OrderStoreInterfaceSnapshotOut) {
      self.order = order
    },
    getDistributor(): string {
      return self.productsHeader.distributor.opId
    },
    getConfigurationCode(): string {
      return self.productsHeader.distributor.configurationCode
    },
    getDistributorMailOrderShopCode(): string {
      return self.productsHeader.distributor.mailOrderShopCode
    },
    getDistributorDetails(): DistributorStoreInterfaceSnapshotOut {
      return getSnapshot(self.productsHeader.distributor)
    },
    getRetailerShopCode(): string {
      return self.productsHeader.retailer.shopCode
    },
    getCoupon(): string {
      return self.header.couponCode || ''
    },
    getLastCoupon(): string {
      return self.lastCoupon
    },
    setLastCoupon(coupon: string) {
      self.lastCoupon = coupon
    },
    setShippingDates(shippingDates: ShippingAddressStoreInterfaceSnapshotIn[]) {
      self.shippingDates.replace(shippingDates)
    },
    error(...args: any) {
      self.status = ShoppingCartStoreAcceptedStatusList.error
      self.errorMessage = args.reduce((accumulator: string, currentValue: any) => (accumulator += `${currentValue}`))
    },
    getSelectedShippingAddress(): ShippingAddressStoreInterfaceSnapshotOut {
      return self.shippingAddresses.getDefaultAddress(getSnapshot(self.shippingAddresses.shippingAddresses))
    },
    isValidProfile(): boolean {
      switch (self.userType) {
        case RETAILER_USER:
          if (self.retailerUserInfo.isValidProfile == true && self.retailerUserInfo.isActive === true) {
            return self.retailerUserInfo.isValidProfile
          }
          break
        case CONSUMER_USER:
          if (self.consumerUserInfo.isValidProfile === true && self.consumerUserInfo.isActive === true) {
            return self.consumerUserInfo.isValidProfile
          }
          break
        default:
          break
      }

      return false
    },
    getProductById(id: string) {
      const productsReducer = (
        accumulator: ProductStoreInterfaceSnapshotOut,
        current: ProductStoreInterfaceSnapshotOut
      ): ProductStoreInterfaceSnapshotOut | void => {
        if (current.id === id) {
          accumulator = current
        }
        return accumulator
      }
      const reducedValue = self.products.reduce(productsReducer)
      return reducedValue.storedValue || {}
    },
    getAcceptedPaymentsTypes(): Array<PaymentTypes> {
      // Temp fallback to avoid missing payment methods
      // correctly setting shipping method is the intended way (albeit being a chore to maintain)
      const shippingMethod = self.shippingMethod.code || self.header.shippingType
      switch (shippingMethod) {
        case SHIPPING_METHOD_EXPRESS:
        case SHIPPING_METHOD_MAILORDER:
          return ['ONLINE']
          break
        case SHIPPING_METHOD_PICKUP:
          const pickUpPayments: Array<any> = getSnapshot(self.shopForPickUp.paymentTypes)
          return pickUpPayments.map((value: { payment: PaymentTypes }) => {
            return value.payment
          }, [])
          break
        default:
          return []
          break
      }
    },
    // for now is only to send event of changing to middleware, required for send gtm event
    updateProductQty(data: ProductUpdateQuantityInterface) {
      return data
    },
    removeProduct(id: string) {
      return id
    },
    cartInteraction(value: string) {
      return value
    },
    shareWithFriends(value: string) {
      return value
    },
    thankYouPageInteraction(value: string) {
      return value
    },
    checkoutPaymentError() {
      return
    },
    orderCompleted(): PaymentMethodStoreInterfaceSnapshotOut {
      return getSnapshot(self.paymentMethod)
    },
    purchaseEvent() {
      return
    },
    deniedCoupon(err: Error) {
      return
    },
    gotoMapFromPayment() {
      return
    }
  }))
