import { isDev } from '../../app/helpers/utils'

const PHOTOFORSE = 'photoforse'
const PHOTOSI = 'photosi'
const API_PHOTOFORSE = `https://api.${PHOTOFORSE}.online`
const API_PHOTOSI = `https://api.${PHOTOSI}.online`
const IP_LABS_SERVICE_DEV = `https://ip-labs-service.${PHOTOFORSE}`
const IP_LABS_SERVICE = `https://iplabs.${PHOTOSI}.com`

type ApiHeadersItem = { url: string }

/**
 * @interface
 * @desc Interface with required structure of api environment
 */
export interface ApiHeadersConfigInterface {
  productsHeader: ApiHeadersItem
  productsHeaderRows: ApiHeadersItem
  headers: ApiHeadersItem
  products: ApiHeadersItem
  shippingMethod: ApiHeadersItem
  shippingAddresses: ApiHeadersItem
  geographics: ApiHeadersItem
  iplabsAuth: ApiHeadersItem
  shopsManager: ApiHeadersItem
  paymentsMethod: ApiHeadersItem
  orderRows: ApiHeadersItem
  couponCode: ApiHeadersItem
  shippingDate: ApiHeadersItem
  userData: ApiHeadersItem
  userTypes: ApiHeadersItem
  consumerUserData: ApiHeadersItem
  consumerUserMarketingData: ApiHeadersItem
  consumerUserProfile: ApiHeadersItem
  retailerUserData: ApiHeadersItem
  orderState: ApiHeadersItem
  cartValidation: ApiHeadersItem
}

export const ApiEnv: ApiHeadersConfigInterface = ((devMode?: boolean): ApiHeadersConfigInterface => {
  switch (devMode) {
    default:
    case true:
      return {
        productsHeader: { url: `${API_PHOTOFORSE}/iplabs-shopping-cart/` },
        productsHeaderRows: { url: `${API_PHOTOFORSE}/iplabs-shopping-cart/rows/` },
        products: { url: `${API_PHOTOFORSE}/iplabs-shopping-cart/` },
        headers: { url: `${API_PHOTOFORSE}/carts/consumer/headers/` },
        shippingMethod: { url: `${API_PHOTOFORSE}/carts/consumer/headers/{id}/shipping-costs` },
        shippingAddresses: { url: `${API_PHOTOFORSE}/consumer-addresses/shipping-addresses/` },
        geographics: { url: `${API_PHOTOFORSE}/geographics/` },
        iplabsAuth: { url: `${IP_LABS_SERVICE_DEV}.online/iplabs/authentication/?next=` },
        shopsManager: { url: `${API_PHOTOFORSE}/shops-manager/{configuration}/` },
        paymentsMethod: { url: `${API_PHOTOFORSE}/orders/consumer/payment-methods` },
        orderRows: { url: `${API_PHOTOFORSE}/iplabs-shopping-cart/rows/` },
        couponCode: { url: `${API_PHOTOFORSE}/promotions/consumer/couponcode` },
        shippingDate: { url: `${API_PHOTOFORSE}/carts/consumer/headers/{id}/delivery-dates` },
        userData: { url: `${API_PHOTOFORSE}/accounts/users/current` },
        userTypes: { url: `${API_PHOTOFORSE}/accounts/user-types/current` },
        consumerUserData: { url: `${API_PHOTOFORSE}/accounts/consumer-users/current` },
        consumerUserMarketingData: { url: `${API_PHOTOFORSE}/accounts/consumer-users/current/marketing-data` },
        consumerUserProfile: { url: `${API_PHOTOFORSE}/accounts/consumer-user-profile/current` },
        retailerUserData: { url: `${API_PHOTOFORSE}/accounts/retailer-users/current` },
        orderState: { url: `${API_PHOTOFORSE}/orders/consumer/headers/` },
        cartValidation: { url: `${API_PHOTOFORSE}/iplabs-shopping-cart/{id}/check` }
      }
    case false: {
      return {
        productsHeader: { url: `${API_PHOTOSI}/iplabs-shopping-cart/` },
        productsHeaderRows: { url: `${API_PHOTOSI}/iplabs-shopping-cart/rows/` },
        products: { url: `${API_PHOTOSI}/iplabs-shopping-cart/` },
        headers: { url: `${API_PHOTOSI}/carts/consumer/headers/` },
        shippingMethod: { url: `${API_PHOTOSI}/carts/consumer/headers/{id}/shipping-costs` },
        shippingAddresses: { url: `${API_PHOTOSI}/consumer-addresses/shipping-addresses/` },
        geographics: { url: `${API_PHOTOSI}/geographics/` },
        iplabsAuth: { url: `${IP_LABS_SERVICE}/iplabs/authentication/?next=` },
        shopsManager: { url: `${API_PHOTOSI}/shops-manager/{configuration}/` },
        paymentsMethod: { url: `${API_PHOTOSI}/orders/consumer/payment-methods` },
        orderRows: { url: `${API_PHOTOSI}/iplabs-shopping-cart/rows/` },
        couponCode: { url: `${API_PHOTOSI}/promotions/consumer/couponcode` },
        shippingDate: { url: `${API_PHOTOSI}/carts/consumer/headers/{id}/delivery-dates` },
        orderState: { url: `${API_PHOTOSI}/orders/consumer/headers/` },
        userData: { url: `${API_PHOTOSI}/accounts/users/current` },
        userTypes: { url: `${API_PHOTOSI}/accounts/user-types/current` },
        consumerUserData: { url: `${API_PHOTOSI}/accounts/consumer-users/current` },
        consumerUserMarketingData: { url: `${API_PHOTOSI}/accounts/consumer-users/current/marketing-data` },
        consumerUserProfile: { url: `${API_PHOTOSI}/accounts/consumer-user-profile/current` },
        retailerUserData: { url: `${API_PHOTOSI}/accounts/retailer-users/current` },
        cartValidation: { url: `${API_PHOTOSI}/iplabs-shopping-cart/{id}/check` }
      }
    }
  }
})(isDev())

export default ApiEnv
