import { ShopForPickUpInterfaceSnapshotOut } from '../state-manager/ShopForPickUp'

export const ShopForPickUpInitialStoreMock: ShopForPickUpInterfaceSnapshotOut = {
  area: '',
  city: '',
  country: '',
  distance: 0,
  email: '',
  iplabsRetailerId: '',
  isInspirationPoint: false,
  isMailorder: false,
  isPickup: false,
  latitude: 0,
  longitude: 0,
  name: '',
  paymentTypes: [],
  phone: '',
  shopCode: '',
  shopType: null,
  street: '',
  zipCode: ''
}
