import { ProductsHeaderApiResultInterface } from '../model/api-result/ProductsHeaderApiResultInterface'
import { ProductsHeaderStoreInterface } from '../state-manager/ProductsHeaderStore'

export const ProductsHeaderAdapter = (header: ProductsHeaderApiResultInterface): ProductsHeaderStoreInterface => {
  return {
    cartState: header.cartState,
    distributor: {
      code: header.distributor.code,
      configurationCode: header.distributor.configurationCode,
      mailOrder: header.distributor.mailOrder,
      opId: header.distributor.opId,
      pickUpStore: header.distributor.pickUpStore,
      express: header.distributor.express,
      url: header.distributor.url || '',
      mailOrderShopCode: header.distributor.mailOrderShopCode || ''
    },
    retailer: header.retailer
      ? {
          shopCode: header.retailer.shopCode || '',
          url: header.retailer.url || ''
        }
      : { shopCode: '', url: '' },
    id: header.id,
    isIpLabsFlow: header.isIpLabsFlow,
    orderNumber: header.orderNumber,
    orderStatusUrl: header.orderStatusUrl || '',
    orderUrl: header.orderUrl || '',
    expirationDate: header.expirationDate,
    selectedShopCode: header.selectedShopCode || '',
    selectedRetailerId: header.selectedRetailerId || ''
  }
}
