import { addMiddleware, getSnapshot } from 'mobx-state-tree'
import { StorageWrapperInterface } from '../../app/service/storage/StorageWrapper'
import { SHOPPING_CART_ITEMKEY_STORAGE } from '../environment/const'
import { ConsumerUserInfoStoreMock } from '../initial-state-mock/ConsumerUserInfoStoreMock'
import ConsumerUserMarketingDataStoreMock from '../initial-state-mock/ConsumerUserMarketingDataStoreMock'
import { OrderStoreMock } from '../initial-state-mock/OrderStoreInitialStoreMock'
import { PaymentMethodInitialStoreMock } from '../initial-state-mock/PaymentMethodInitialStoreMock'
import QueryStringParamsAcceptedStoreMock from '../initial-state-mock/QueryStringParamsAcceptedStoreMock'
import { RetailerUserInfoStoreMock } from '../initial-state-mock/RetailerUserInfoStoreMock'
import { ShippingDataForRetailerStoreMock } from '../initial-state-mock/ShippingDataForRetailerStoreMock'
import { ShippingMethodStoreMock } from '../initial-state-mock/ShippingMethodStoreMock'
import { ShopForPickUpInitialStoreMock } from '../initial-state-mock/ShopForPickUpMock'
import { ShoppingCartHeaderStoreMock } from '../initial-state-mock/ShoppingCartHeaderStroreMock'
import { ShoppingCartProductsHeaderStoreMock } from '../initial-state-mock/ShoppingCartProductsHeaderStoreMock'
import { ShoppingCartInitialState } from '../initial-state-mock/ShoppingCartStoreMock'
import { UserInfoInitialStoreMock } from '../initial-state-mock/UserIndoInitialStoreMock'
import { UserTypesInitialStoreMock } from '../initial-state-mock/UserTypesInitialStoreMock'
import { ShippingAddressInterface } from '../service/ShippingAddress'
import { ShoppingCartDataServiceInterface } from '../service/ShoppingCartData'
import { ConsumerUserInfoStoreInterface } from '../state-manager/ConsumerUserInfoStore'
import { ConsumerUserMarketingDataStoreInterface } from '../state-manager/ConsumerUserMarketingDataStore'
import { OrderStoreInterface } from '../state-manager/OrderStore'
import { PaymentMethodStoreInterface } from '../state-manager/PaymentMethodStore'
import { ProductsHeaderStoreInterface } from '../state-manager/ProductsHeaderStore'
import { QueryStringParamsAcceptedStoreInterface } from '../state-manager/QueryStringParamsAcceptedStore'
import { RetailerUserInfoStoreInterface } from '../state-manager/RetailerUserInfoStore'
import { ShippingAddressesStoreInterface } from '../state-manager/ShippingAddresses'
import { ShippingDataForRetailerStoreInterface } from '../state-manager/ShippingDataForRetailer'
import { ShippingMethodStoreInterface } from '../state-manager/ShippingMethodStore'
import { ShopForPickUpInterface } from '../state-manager/ShopForPickUp'
import { ShoppingCartHeaderStoreInterface } from '../state-manager/ShoppingCartHeaderStore'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'
import { UserInfoStoreInterface } from '../state-manager/UserInfoStore'
import { UserTypesStoreInterface } from '../state-manager/UserTypesStore'

export interface ShoppingCartLoaderInitializedInterface {
  storage: StorageWrapperInterface
  rootStore: ShoppingCartStoreInterface
  addressService: ShippingAddressInterface
  cartDataService: ShoppingCartDataServiceInterface
}

/**
 * @class
 * @desc class with business logic for initializa the storage and the store of current application
 */
export class ShoppingCartLoader {
  private shoppingCartDefaultStatus: ShoppingCartStoreInterface = ShoppingCartInitialState

  constructor(
    private storage: StorageWrapperInterface, //local storage
    private store: {
      headerStore: ShoppingCartHeaderStoreInterface
      orderStore: OrderStoreInterface
      productsHeaderStore: ProductsHeaderStoreInterface
      shippingMethodStore: ShippingMethodStoreInterface
      shippingAddressesStore: ShippingAddressesStoreInterface
      shopForPickUp: ShopForPickUpInterface
      paymentMethod: PaymentMethodStoreInterface
      userInfo: UserInfoStoreInterface
      userTypes: UserTypesStoreInterface
      consumerUserInfo: ConsumerUserInfoStoreInterface
      retailerUserInfo: RetailerUserInfoStoreInterface
      shippingDataForRetailer: ShippingDataForRetailerStoreInterface
      consumerUserMarketingData: ConsumerUserMarketingDataStoreInterface
      queryStringParamsAccepted: QueryStringParamsAcceptedStoreInterface
    },
    private shoppingCartStore: ShoppingCartStoreInterface // store maanger // mobx state tree
  ) {}

  /**
   * @method
   * @desc initialization business logic of ShoppingCart storage and persist con call actions
   */
  public createStore() {
    this.shoppingCartDefaultStatus.productsHeader = this.store.productsHeaderStore.create(ShoppingCartProductsHeaderStoreMock)
    this.shoppingCartDefaultStatus.header = this.store.headerStore.create(ShoppingCartHeaderStoreMock)
    this.shoppingCartDefaultStatus.shippingMethod = this.store.shippingMethodStore.create(ShippingMethodStoreMock)
    this.shoppingCartDefaultStatus.shippingAddresses = this.store.shippingAddressesStore.create()
    this.shoppingCartDefaultStatus.shopForPickUp = this.store.shopForPickUp.create(ShopForPickUpInitialStoreMock)
    this.shoppingCartDefaultStatus.paymentMethod = this.store.paymentMethod.create(PaymentMethodInitialStoreMock)
    this.shoppingCartDefaultStatus.userInfo = this.store.userInfo.create(UserInfoInitialStoreMock)
    this.shoppingCartDefaultStatus.userTypes = this.store.userTypes.create(UserTypesInitialStoreMock)
    this.shoppingCartDefaultStatus.order = this.store.orderStore.create(OrderStoreMock)
    this.shoppingCartDefaultStatus.consumerUserInfo = this.store.consumerUserInfo.create(ConsumerUserInfoStoreMock)
    this.shoppingCartDefaultStatus.retailerUserInfo = this.store.retailerUserInfo.create(RetailerUserInfoStoreMock)
    this.shoppingCartDefaultStatus.shippingDataForRetailer = this.store.shippingDataForRetailer.create(ShippingDataForRetailerStoreMock)
    this.shoppingCartDefaultStatus.consumerUserMarketingData = this.store.consumerUserMarketingData.create(
      ConsumerUserMarketingDataStoreMock
    )

    this.shoppingCartDefaultStatus.queryStringParamsAccepted = this.store.queryStringParamsAccepted.create(
      QueryStringParamsAcceptedStoreMock
    )
    this.shoppingCartStore = this.shoppingCartStore.create(this.shoppingCartDefaultStatus)

    /**
     * persist store on call actions
     */
    addMiddleware(this.shoppingCartStore, (call: any, next: any) => {
      next(call, (value: any) => {
        this.persistSnapshot()
        return value
      })
    })

    return this.shoppingCartStore
  }

  /**
   * persist data in store
   */
  private persistSnapshot() {
    this.storage.persist(SHOPPING_CART_ITEMKEY_STORAGE, JSON.stringify(getSnapshot(this.shoppingCartStore)))
  }
}
