import { getRetailerShopCodeFromCookie } from '../../../../app/helpers/utils'
import { ShoppingCartStoreInterface } from '../../../state-manager/ShoppingCartStore'

export interface ShopCodeManagerInterface {
  getCurrentShopCode(): string | void
  isUniqueRetailer(): boolean
}

export class ShopCodeManager implements ShopCodeManagerInterface {
  private uniqueShopCode: string = ''
  private selectedShopCode: string = ''
  constructor(private rootStore: ShoppingCartStoreInterface) {
    this.rootStore = rootStore

    this.uniqueShopCode = getRetailerShopCodeFromCookie() || ''
    this.selectedShopCode = this.rootStore.header.shopCode || this.rootStore.productsHeader.selectedShopCode || ''
  }
  isUniqueRetailer(): boolean {
    return this.uniqueShopCode.length > 0
  }
  getCurrentShopCode(): string {
    if (this.isValidShopCode(this.uniqueShopCode)) {
      return this.uniqueShopCode
    }
    if (this.isValidShopCode(this.selectedShopCode)) {
      return this.selectedShopCode
    }
    return ''
  }
  private isValidShopCode(shopCode: any): boolean {
    if (typeof shopCode === 'string' && shopCode && shopCode.length > 0) {
      return true
    }
    return false
  }
}
