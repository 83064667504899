import { GOOGLE_MAPS_API_KEY } from '../environment/const'

export interface GoogleApiPlacesInterface {
  load(): Promise<HTMLScriptElement>
}

export class GoogleApiPlaces implements GoogleApiPlacesInterface {
  private isIntalized: boolean = false
  private scriptTag: HTMLScriptElement = document.createElement('script')
  load(): Promise<HTMLScriptElement> {
    return new Promise((resolve, reject) => {
      if (this.isIntalized) {
        return resolve(this.scriptTag)
      }

      this.scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`

      this.scriptTag.onload = () => {
        this.isIntalized = true
        resolve(this.scriptTag)
      }

      this.scriptTag.onerror = (err) => {
        this.isIntalized = false
        reject(err)
      }

      document.body.appendChild(this.scriptTag)
    })
  }
}

export default GoogleApiPlaces
