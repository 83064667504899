import { i18n } from 'i18next'
import { inject } from 'mobx-react'
import React from 'react'
import Picture from '../../../app/components/Picture'
import { TransNS } from '../../../app/components/TransNS'
import promotionIllustation from '../../../assets/images/svg/promotion_illustration.svg'
import { ProviderServiceInterface } from '../../model/CustomServiceProvider'
import { ShoppingCartStoreInterface } from '../../state-manager/ShoppingCartStore'
import { Button } from '../generic/Button'

export interface AcceptedCouponComponentInterface {
  readonly rootStore?: ShoppingCartStoreInterface
  readonly i18n?: i18n
  onClose: () => void
}

export const AcceptedCoupon: React.FC<AcceptedCouponComponentInterface> = inject((states: ProviderServiceInterface) => ({
  rootStore: states.service.rootStore,
  i18n: states.service.i18n
}))(({ rootStore, i18n, onClose }) => {
  if (!rootStore || !i18n) {
    const ErrorGeneric = React.lazy(() => import('../../../app/components/ErrorGeneric'))
    return <ErrorGeneric code={110009} i18nKeyMessage={'service_failed_injection'} />
  }

  const savings = new Intl.NumberFormat([i18n.language], { style: 'currency', currency: rootStore.header.currencyCode }).format(
    rootStore.header.vatIncludedFullTotal - rootStore.header.vatIncludedTotal
  )

  const total = new Intl.NumberFormat([i18n.language], { style: 'currency', currency: rootStore.header.currencyCode }).format(
    rootStore.header.vatIncludedTotal
  )
  return (
    <div className="accepted-coupon tc">
      <Picture src={promotionIllustation} />
      <br />
      <h3>
        <TransNS i18nKey="accepted-coupon:title">Fantastico!</TransNS>
      </h3>
      <p>
        <TransNS i18nKey="accepted-coupon:applied_coupon">Hai applicato il codice</TransNS>
        {` ${rootStore.header.couponCode} `}
        <TransNS i18nKey="accepted-coupon:in_cart_products">ai prodotti nel carrello!</TransNS>{' '}
        <TransNS i18nKey="accepted-coupon:saving">Così risparmi</TransNS>
        {` ${savings}! `}
        <br />
        <TransNS i18nKey="accepted-coupon:new_total">Il nuovo totale è di</TransNS>
        {` ${total}`}
      </p>
      <Button block primary onClick={onClose}>
        <TransNS i18nKey="accepted-coupon:close">Chiudi</TransNS>
      </Button>
    </div>
  )
})

export default AcceptedCoupon
