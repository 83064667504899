/**
 * @interface CartAcceptedStateInteface real cart status from api result
 */
export interface CartAcceptedStateInteface {
  new: 'New'
  waitingForPayment: 'WaitingPayment'
  waitingConfirm: 'WaitingConfirm'
  waitingDownload: 'WaitingDownload'
  confirmed: 'Confirmed'
  cancelled: 'Cancelled'
}

export const CART_ACCEPTED_STATUS: CartAcceptedStateInteface = {
  new: 'New',
  waitingForPayment: 'WaitingPayment',
  waitingConfirm: 'WaitingConfirm',
  waitingDownload: 'WaitingDownload',
  confirmed: 'Confirmed',
  cancelled: 'Cancelled'
}
export type cartState = 'New' | 'WaitingPayment' | 'WaitingConfirm' | 'WaitingDownload' | 'Confirmed' | 'Cancelled'
