import { DidomiSDK, IDidomiObject } from '@didomi/react'
import { inject } from 'mobx-react'
import React from 'react'
import { ProviderServiceInterface } from '../../domain/model/CustomServiceProvider'
import { AmplitudeEventInterface } from '../../domain/service/AmplitudeEvent'
import { DIDOMI_AMPLITUDE_CONSENT_ID, DIDOMI_API_KEY, DIDOMI_CONSENT_ID } from '../environment/const'
import { Breakpoints } from '../service/responsive/mediaQueries'

interface CookieConsentInterface {
  readonly amplitudeEvent?: AmplitudeEventInterface
}

const CookieConsent: React.FC<CookieConsentInterface> = inject((states: ProviderServiceInterface) => ({
  amplitudeEvent: states.service.amplitudeEvent
}))(({ amplitudeEvent }) => {
  // Consent ID presence is actually used to enable/disable didomi activation
  if (!DIDOMI_CONSENT_ID) return null
  const platform = Breakpoints.isMobile() ? 'Mobile' : 'Desktop'

  const handleDidomiReady = (didomi: IDidomiObject) => {
    console.log('Didomi SDK is loaded and ready', didomi)

    // Enable systems that are under consent
    const amplitudeEnabled = didomi.getUserConsentStatusForAll().vendors.enabled.includes(DIDOMI_AMPLITUDE_CONSENT_ID)
    if (amplitudeEnabled) {
      amplitudeEvent?.initClient(platform)
    }
  }

  return (
    <DidomiSDK
      apiKey={DIDOMI_API_KEY}
      noticeId={DIDOMI_CONSENT_ID}
      iabVersion={2}
      embedTCFStub={true}
      gdprAppliesGlobally={true}
      onReady={handleDidomiReady}
      onConsentChanged={(cwtToken) => {
        console.log('A consent has been given/withdrawn', cwtToken)
        window.location.reload() // just reload to start fresh with updated consent
      }}
      onNoticeShown={() => console.log('Didomi Notice Shown')}
      onNoticeHidden={() => console.log('Didomi Notice Hidden')}
      onNoticeBackdropclick={() => console.log('Didomi Notice Backdrop Click')}
      onNoticeClickAgree={() => console.log('Didomi Notice Click Agree')}
      onNoticeClickMoreInfo={() => console.log('Didomi Notice Click More Info')}
      onPreferencesClickAgreeToAll={() => console.log('Didomi Preferences Click Agree to All')}
      onPreferencesClickDisagreeToAll={() => console.log('Didomi Preferences Click Disagree to All')}
      onPreferencesClickPurposeAgree={(purposeId) => console.log('Didomi Preferences Click Purpose Agree', purposeId)}
      onPreferencesClickPurposeDisagree={(purposeId) => console.log('Didomi Preferences Click Purpose Disagree', purposeId)}
      onPreferencesClickViewVendors={() => console.log('Didomi Preferences Click View Vendors')}
      onPreferencesClickSaveChoices={() => console.log('Didomi Preferences Click Save Choices')}
      onPreferencesClickVendorAgree={(vendorId) => console.log('Didomi Preferences Click Vendor Agree', vendorId)}
      onPreferencesClickVendorDisagree={(vendorId) => console.log('Didomi Preferences Click Vendor Disagree', vendorId)}
      onPreferencesClickVendorSaveChoices={() => console.log('Didomi Preferences Click Vendor Save Choices')}
    />
  )
})

export default CookieConsent
