import { ShoppingCartStoreAcceptedStatusList } from '../model/ShoppingCartStoreAcceptedStatus'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'

export const ShoppingCartInitialState: ShoppingCartStoreInterface = {
  products: [],
  productsHeader: {},
  headers: {},
  status: ShoppingCartStoreAcceptedStatusList.not_initialized,
  error: '',
  errorMessage: '',
  shippingMethod: {},
  shippingAddresses: {},
  shopForPickUp: {},
  progress: 0,
  userInfo: {},
  userType: '',
  userTypes: {},
  shippingDataForRetailer: {},
  createUserToken: false,
  shippingMethodList: [],
  lastCoupon: ''
}
