import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface CountryStoreInterface extends Instance<typeof CountryStore> {}
export interface CountryStoreInterfaceSnapshotIn extends SnapshotIn<typeof CountryStore> {}
export interface CountryStoreInterfaceSnapshotOut extends SnapshotOut<typeof CountryStore> {}

export const CountryStore = types.model('CountryStore', {
  iso: types.number,
  isoAlpha2: types.string,
  isoAlpha3: types.string,
  name: types.string
})
