import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { acceptedShippingMethodNames } from '../environment/const'

export interface ShoppingCartHeaderStoreInterface extends Instance<typeof ShoppingCartHeaderStore> {}
export interface ShoppingCartHeaderStoreInterfaceSnapshotIn extends SnapshotIn<typeof ShoppingCartHeaderStore> {}
export interface ShoppingCartHeaderStoreInterfaceSnapshotOut extends SnapshotOut<typeof ShoppingCartHeaderStore> {}

export const ShoppingCartHeaderStore = types
  .model('ShoppingCartHeaderStore', {
    billingAddressArea: types.string,
    billingAddressCity: types.string,
    billingAddressCompanyName: types.string,
    billingAddressCountry: types.string,
    billingAddressEmail: types.string,
    billingAddressFirstName: types.string,
    billingAddressId: types.string,
    billingAddressLastName: types.string,
    billingAddressNumber: types.string,
    billingAddressPhone: types.string,
    billingAddressStreet: types.string,
    billingAddressZipCode: types.string,
    confirmationDate: types.string,
    confirmed: types.boolean,
    cumulativeCoupon: types.boolean,
    couponCode: types.string,
    creationDate: types.string,
    currencyCode: types.string,
    formattedVatIncludedCouponValue: types.string,
    formattedVatIncludedFullTotal: types.string,
    formattedVatIncludedPointsValue: types.string,
    formattedVatIncludedShippingCost: types.string,
    formattedVatIncludedSubTotal: types.string,
    formattedVatIncludedTotal: types.string,
    id: types.string,
    note: types.string,
    paid: types.boolean,
    paymentType: types.string,
    pd: types.string,
    pointsSpent: types.number,
    removedCouponCode: types.string,
    shippingAddressArea: types.string,
    shippingAddressCity: types.string,
    shippingAddressCompanyName: types.string,
    shippingAddressCountry: types.string,
    shippingAddressEmail: types.string,
    shippingAddressFirstName: types.string,
    shippingAddressId: types.string,
    shippingAddressLastName: types.string,
    shippingAddressNumber: types.string,
    shippingAddressPhone: types.string,
    shippingAddressStreet: types.string,
    shippingAddressZipCode: types.string,
    shippingType: types.string,
    shopCode: types.string,
    vatIncludedCouponValue: types.number,
    vatIncludedFullTotal: types.number,
    vatIncludedPointsValue: types.number,
    vatIncludedShippingCost: types.number,
    vatIncludedSubTotal: types.number,
    vatIncludedTotal: types.number,
    scalapayInstallment: types.number
  })
  .actions((self: any): any => ({
    setConfirmed(confirmed: boolean): void {
      self.confirmed = confirmed
    },
    setShopCode(code: string) {
      self.shopCode = code
    },
    setShippingType(type: acceptedShippingMethodNames) {
      self.shippingType = type
    },
    setShippingAddressId(id: string) {
      self.id = id
    },
    setCouponCode(couponCode: string) {
      self.couponCode = couponCode
    }
  }))

/*
  .actions((self: any): any => ({
    setId(id: string): void {
      self.id = id
    }
  }))
*/
export default ShoppingCartHeaderStore
