import { Provider } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import CookieConsent from '../../app/components/CookieConsent'
import ErrorGeneric from '../../app/components/ErrorGeneric'
import { LOGO } from '../../app/environment/const'
import {
  decodeToken,
  getUserTokenFromCookie,
  isDev,
  isValidGuid,
  isValidToken,
  pathnameFromSecondParams,
  setUserTokenCookie,
  tokenIsExpired
} from '../../app/helpers/utils'
import { DefaultServiceProvidedInterface } from '../../app/models/ServiceProvider'
import { QueryStringParamsAccepted } from '../../app/service/queryString/queryStringService'
import { Breakpoints } from '../../app/service/responsive/mediaQueries'
import {
  LAST_GUID_STORAGE_KEY,
  LOGIN_CONSUMER,
  PAGE_TYPE_SHOPPING_CART,
  PAGE_TYPE_SHOPPING_CART_ERROR,
  SOFTWARECODE_KEY,
  SOFTWARE_CODE,
  SOFTWARE_VERSION,
  SOFTWARE_VERSION_KEY
} from '../environment/const'
import { setUserInfo } from '../helpers/setUserInfo'
import { initializeCustomService } from '../loader/InitializeCustomService'
import { GlobalsProvidedVarsInterface, GlobalsResponsive } from '../model/CustomGlobalsProvided'
import { ServiceProvidedInterface } from '../model/CustomServiceProvider'
import { CART_ACCEPTED_STATUS } from '../model/ShoppingCartAcceptedStatus'
import { GTMEvent } from '../service/GTMEvent'
import { PathNavigatorInterface } from '../service/PathNavigatorService'
import { DistributorStoreInterfaceSnapshotOut } from '../state-manager/DistributorStore'
import ShoppingCartEmpty from './CartEmpty'
import ShoppingCartContainer from './Container'
import GlobalsContainer from './globals/GlobalsContainer'
import GlobalSpinner from './globals/GlobalSpinner'

interface CustomProviderComponentInterface {
  service: ServiceProvidedInterface
  globals: GlobalsProvidedVarsInterface
  children?: React.ReactChildren
}

const updateLogo = (i18n: any) => {
  const headerLogo = document.getElementById('header-logo-link')

  if (headerLogo && LOGO && LOGO.src) {
    //@ts-ignore
    Object.keys(LOGO.src).forEach((language) => {
      if (i18n.language === language) {
        const image: HTMLImageElement = new Image()
        //@ts-ignore
        image.src = `${LOGO.src[language]}`
        image.onload = () => {
          const logo = headerLogo.querySelector('img')
          if (logo) {
            logo.setAttribute('src', image.src)
          }
        }
      }
    })
  }
}

export const CustomProvider: React.FC<CustomProviderComponentInterface> = ({ service, globals, children }) => {
  const Footer = React.lazy(() => import('../../app/components/Footer'))

  const [provivedContent, setProvidedContent] = useState({ service: service, globals: globals })
  const [mainComponent, setMainComponent] = useState(<GlobalSpinner visible={true} />)

  const isDesktop = (() => Breakpoints.isDesktop())()
  const isMobile = (() => Breakpoints.isMobile())()
  const platform = isMobile ? 'Mobile' : 'Desktop'

  const handleMainComponent = (components: any) => {
    if (components && React.Children.count(components) > 0) {
      setMainComponent(
        <>
          <CookieConsent />
          {components}
          <Footer />
          <GlobalSpinner visible={false} />
        </>
      )
    } else {
      console.error('invalid components', components)
    }
  }

  useEffect(() => {
    const { queryString, storage, navigator, i18n } = service as DefaultServiceProvidedInterface

    //@ts-ignore
    const NavigatorSelfPushState = (i18n: i18n, navigator: PathNavigatorInterface) => {
      window.history.pushState(null, document.title, navigator.create(i18n.language, pathnameFromSecondParams()))
    }

    const responsive: GlobalsResponsive = { isDesktop: isDesktop, isMobile: isMobile }
    Object.assign(globals, { responsive })

    const {
      token,
      guid,
      softwareCode: softwareCodeParam,
      softwareVersion: softwareVersionParam
    }: QueryStringParamsAccepted = queryString.getAcceptedParams()

    const softwareCodeStored = storage.load(SOFTWARECODE_KEY)
    const softwareVersionStored = storage.load(SOFTWARE_VERSION_KEY)

    storage.persist(SOFTWARECODE_KEY, softwareCodeParam || softwareCodeStored || SOFTWARE_CODE)
    storage.persist(SOFTWARE_VERSION_KEY, softwareVersionParam || softwareVersionStored || SOFTWARE_VERSION)

    /**
     * @desc starts control on language change
     */
    if (i18n.isInitialized) {
      window.history.pushState(null, document.title, navigator.create(i18n.language, pathnameFromSecondParams()))
      isDesktop ? updateLogo(i18n) : null

      i18n.on('languageChanged', (lng: string) => {
        window.history.pushState(null, document.title, navigator.create(lng, pathnameFromSecondParams()))
      })
    }

    //IF TOKEN IS EMPTY OR UNDEFINED TRY TO GET FROM COOKIES
    let currentToken = token
    if (!token || token.length <= 0) {
      currentToken = getUserTokenFromCookie()
    }

    // crash pages, dont require full cart functions
    if (window.location.pathname.indexOf(`/${i18n.language}/crash`) > -1) {
      const Crash = React.lazy(() => import('./routers/CrashRouter'))
      handleMainComponent(<Crash token={decodeToken(currentToken || '') || undefined} />)
    }

    // redirect to login page
    if (!currentToken || !isValidToken(currentToken) || tokenIsExpired(currentToken)) {
      window.location.href = `${LOGIN_CONSUMER.replace('{lang}', i18n.language)}${encodeURI(window.location.origin)}`
      return
    }

    // accept guid
    if (guid && isValidGuid(guid)) {
      navigator.assignParams({ guid })
    } else {
      navigator.assignParams({ guid: '' })
    }

    // STARTS CUSTOM SERVICE OF DOMAIN
    initializeCustomService({ serviceProvided: service, guid, token: currentToken, platform }).subscribe(
      (service: ServiceProvidedInterface) => {
        const { rootStore, sentry, gtmEvent } = service
        const currentGuid = guid && isValidGuid(guid) ? guid : rootStore.header.id
        navigator.assignParams({ guid: currentGuid })
        // @ts-ignore
        if (!isValidGuid(guid) && isValidGuid(currentGuid)) {
          NavigatorSelfPushState(i18n, navigator)
        }

        gtmEvent.wSetup(i18n.language, PAGE_TYPE_SHOPPING_CART)

        storage.persist(LAST_GUID_STORAGE_KEY, currentGuid)

        if (rootStore.getConfigurationCode()) {
          const distributor: DistributorStoreInterfaceSnapshotOut = rootStore.getDistributorDetails()

          const header = document.getElementById('header-logo-link')
          if (header) header.setAttribute('href', distributor.url || '')
        }

        setProvidedContent({ service, globals })
        handleMainComponent(<ShoppingCartContainer key={`ShoppinCart-${Date.now()}`} />)

        /*
           Controlla se ci sono divergenze tra ordine e carrello, vince l'ordine (header)
           Non dovrebbe succedere, ma cmq se succede almeno l'utente vede la schermata di conferma
           */
        if (rootStore.header.confirmed === true && rootStore.productsHeader.getCartState() === CART_ACCEPTED_STATUS.new) {
          rootStore.setCartState(CART_ACCEPTED_STATUS.confirmed)
          const err: Error = new Error('status divergence between cart and order status')
          sentry.captureException(err)
        }

        // TODO - serve per tamponare in attesa di una soluzione finale lato auth photosi
        try {
          if (!currentToken) return
          setUserTokenCookie(currentToken)
          rootStore.setCreateUserToken(true)
          setUserInfo(rootStore)
        } catch (err) {
          sentry.captureException(err)
        }

        if (isDev()) Object.assign(global, { service, globals, responsive })
      },
      (err: Error) => {
        const language = i18n.language
        const country = i18n.language
        const pageType = PAGE_TYPE_SHOPPING_CART_ERROR
        const currencyCode = ''
        const userID = ''

        window.dataLayer.push(GTMEvent.getWSetupOnbject({ language, country, pageType, currencyCode, userID }))

        let code: number = 0

        if (err.message && err.message.indexOf('404') !== -1) {
          code = 404
        }

        if (err.message && err.message.indexOf('500') !== -1) {
          code = 500
        }

        globals.globalSpinner.visible = false

        switch (code) {
          case 404:
            handleMainComponent(
              <div className="phs-consumer-cart phs-container tc">
                <ShoppingCartEmpty key={`ShoppingCartEmpty-${Date.now()}`} />
              </div>
            )
            break
          case 0:
            handleMainComponent(<ErrorGeneric message={err.message} />)
            break
          case 500:
          default:
            handleMainComponent(<ErrorGeneric code={Number(code)} message={err.message} />)
            break
        }
      }
    )
  }, [])

  return (
    <Provider {...provivedContent}>
      <GlobalsContainer />
      {children}
      {mainComponent}
    </Provider>
  )
}

export default CustomProvider
