import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface DistributorStoreInterface extends Instance<typeof DistributorStore> {}
export interface DistributorStoreInterfaceSnapshotIn extends SnapshotIn<typeof DistributorStore> {}
export interface DistributorStoreInterfaceSnapshotOut extends SnapshotOut<typeof DistributorStore> {}

export const DistributorStore = types
  .model('DistributorStore', {
    code: types.string,
    configurationCode: types.string,
    mailOrder: types.boolean,
    opId: types.number,
    pickUpStore: types.boolean,
    express: types.boolean,
    url: types.string,
    mailOrderShopCode: types.string
  })
  .actions((self: any): any => ({
    getShippintMethod() {
      const shippingMethod = [
        { method: 'mailOrder', value: self.mailOrder },
        { method: 'pickUpStore', value: self.pickUpStore },
        { method: 'express', value: self.express }
      ]
        .filter(({ value }) => value)
        .map(({ method }) => method.toLowerCase())

      return shippingMethod
    }
  }))
