import { ConsumerUserMarketingDataStoreInterfaceSnapshotOut } from '../state-manager/ConsumerUserMarketingDataStore'

export const ConsumerUserMarketingDataStoreMock: ConsumerUserMarketingDataStoreInterfaceSnapshotOut = {
  hasAlreadyOrdered: false,
  id: 0,
  lastLoginType: null,
  lastShippingType: null,
  lastShopCode: null,
  registrationDate: null
}
export default ConsumerUserMarketingDataStoreMock
