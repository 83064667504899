import jsCookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { Observable } from 'rxjs'
import { GDOInfoDataInterface } from '../../domain/model/GDOInfoDataInterface'
import { UniqueRetailerDataCookieInteface } from '../../domain/model/RetailerDataCookieInterface'
import { UserTokenInterface } from '../../domain/model/UserTokenInterface'
import {
  DISTRIBUTOR_DATA_COOKIE,
  GA_CID_COOKIE,
  LANGUAGE_COOKIE,
  TOKEN_COOKIE,
  UNIQUE_RETAILER_COOKIE,
  USERINFO_COOKIE
} from '../environment/const'
import { XHROptions, XHRRequestObservable } from '../service/XHRRequest/XHRRequestObservable'
/**
 * @desc Get browser language from navigator
 * @return {string}
 */
export function getBrowserLanguage(): string {
  return navigator.language.includes('-') ? navigator.language.split('-')[0] : navigator.language
}

/**
 * @desc The workspace is development?
 * @return {boolean}
 */
export function isDev() {
  if (!(window && window.location)) {
    return
  }

  if (
    window &&
    window.location &&
    window.location.origin &&
    (window.location.origin.indexOf('localhost') !== -1 ||
      window.location.origin.indexOf('photoforse') !== -1 ||
      window.location.origin.indexOf('ngrok') !== -1 ||
      window.location.origin.indexOf('0.0.0.0') !== -1 ||
      window.location.origin.indexOf('192.168.') !== -1)
  ) {
    return true
  }
  return false
}

/**
 * @desc Function to get the current year
 * @return {number}
 */
export function getCurrentYear(): number {
  return new Date().getUTCFullYear()
}

/**
 * @desc Get language from cookies (customized 4 Photosi.com domains)
 * @return {string}
 */
export function getLanguage(): string {
  const languageCookie = jsCookies.get(`${LANGUAGE_COOKIE}`)
  if (languageCookie) return languageCookie
  return getBrowserLanguage()
}

/**
 * @desc get google analytics cookie wit client id
 */
export function getGaCidFromCookie(): string {
  return jsCookies.get(`${GA_CID_COOKIE}`) || ''
}

/**
 * @desc return usetToken cookie value
 */
export function getUserTokenFromCookie(): string {
  return jsCookies.get(`${TOKEN_COOKIE}`) || ''
}

/**
 * @desc return cookie with basic info of user
 */
export function getUserInfoCookie(): string {
  return jsCookies.get(`${USERINFO_COOKIE}`) || ''
}

export function getCurrentDomain(): string {
  return window.location.hostname.split('.').slice(1).join('.')
}

/**
 * @desc get marketing cookie is accepted
 */
export function getCookieMarketingData(): string {
  const cookie = jsCookies.get('marketing')
  return cookie === 'accepted' ? '1' : '0'
}

/**
 * @desc get marketing analytics is accepted
 */
export function getCookieAnalyticsData(): string {
  const cookie = jsCookies.get('analytics')
  return cookie === 'accepted' ? '1' : '0'
}

/**
 * @desc set current userToken
 * @param token
 */
export function setUserTokenCookie(token: string): string | false {
  if (getUserTokenFromCookie() !== token && isValidToken(token) && !tokenIsExpired(token)) {
    const currentDomain = getCurrentDomain()
    jsCookies.set(`${TOKEN_COOKIE}`, token, { domain: currentDomain })
    return token
  }
  return false
}

const hash = async (string: string): Promise<string> => {
  const utf8: Uint8Array = new TextEncoder().encode(string)
  const hashBuffer: ArrayBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray: number[] = Array.from(new Uint8Array(hashBuffer))
  const hashHex: string = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('')
  return hashHex
}

/**
 * @desc set user info cookie
 * @param id
 * @param email
 * @param firstName
 */
export async function setUserInfoCookie(id: number, email: string, firstName: string): Promise<string | false> {
  const getPartialEmail = (email: string) => email.split('@')[0] || ''
  const currentName: string = firstName || getPartialEmail(email)
  let hashedEmailParam: string = ''
  try {
    const hashedEmail: string = await hash(email.toLowerCase())
    hashedEmailParam = `&hashedEmail=${hashedEmail}`
  } catch (err) {
    console.error('Unable to create hashed email', err)
  }
  let hashedFirstNameParam: string = ''
  try {
    const hashedFirstName: string = await hash(currentName.toLowerCase())
    hashedFirstNameParam = `&hashedFirstName=${hashedFirstName}`
  } catch (err) {
    console.error('Unable to create hashed first name', err)
  }
  const cookieString = `id=${id}&email=${encodeURIComponent(email)}&firstName=${currentName}${hashedEmailParam}${hashedFirstNameParam}`
  if (jsCookies.get(`${USERINFO_COOKIE}`) !== cookieString) {
    const currentDomain = getCurrentDomain()
    jsCookies.set(`${USERINFO_COOKIE}`, cookieString, { domain: currentDomain })
    return cookieString
  }
  return false
}

/**
 * @desc set generic cookie
 * @param key
 * @param value
 */
export function setCookie(key: string, value: string | number, expires: number = 7): string | void {
  if (key.length <= 0) {
    return
  }
  const currentDomain = getCurrentDomain()
  jsCookies.set(key, `${value}`, { domain: currentDomain, expires: expires })
  return jsCookies.get(key)
}

export function getCookie(key: string): string | void {
  return jsCookies.get(key)
}

/**
 * @desc decode and parse json in encodedURI string
 * @param data
 */
export const decodeEncodedUriComponentJson = (data: string) => {
  const decodedJson = decodeURIComponent(data) || ''
  if (!decodedJson || (decodedJson && decodedJson.length <= 0)) {
    return ''
  } else {
    return JSON.parse(decodedJson) || ''
  }
}

/**
 * @desc get GDOinfoData from cookie value
 */
export function getGDOInfoData(): GDOInfoDataInterface {
  const encodedJson = jsCookies.get(`${DISTRIBUTOR_DATA_COOKIE}`) || ''
  return decodeEncodedUriComponentJson(encodedJson)
}

/**
 * @desc get distributor code from gdo cookie
 */
export function getDistributorCodeFromGdoCookie(): string {
  const gdoData: GDOInfoDataInterface = getGDOInfoData()
  return gdoData && gdoData.distributorCode ? gdoData.distributorCode : ''
}

/**
 * @desc get retailer data from cookie
 */
export function getUniqueRetailerDataFromCookie(): UniqueRetailerDataCookieInteface {
  const encodedJson = jsCookies.get(`${UNIQUE_RETAILER_COOKIE}`) || ''
  return decodeEncodedUriComponentJson(encodedJson)
}

/**
 * @desc get retailer shop code from cookie
 */
export function getRetailerShopCodeFromCookie(): string {
  const shopData = getUniqueRetailerDataFromCookie()
  return shopData && shopData.shopCode ? shopData.shopCode : ''
}

/**
 * @desc return json from xhr call encapsualted in the observable
 * @param url
 * @return {Observable}
 */
export function getJSON$<T>(params: XHROptions): Observable<T> {
  const xhrRequestObv = new XHRRequestObservable()
  return xhrRequestObv.XHR$(params)
}

/**
 * @desc return the browser is IE or not
 */
export function isIE(): boolean {
  const ua = navigator.userAgent
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
}

/**
 * @desc capitalize text
 * @param text
 */
export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

/**
 * @desc decode specific token
 * */
export const decodeToken = (token: string): UserTokenInterface | void => {
  if (isValidToken(token)) {
    //@ts-ignore
    return jwtDecode(token)
  }
}

/**
 * @desc check expire date of token
 * @param tokenEncoded
 */
export const tokenIsExpired = (tokenEncoded: string): boolean => {
  // @ts-ignore
  const tokenDecoded: UserTokenInterface = decodeToken(tokenEncoded)
  if (!tokenDecoded || (tokenDecoded && !tokenDecoded.exp)) {
    return true
  }
  return tokenDecoded.exp < new Date().getTime() / 1000
}

/**
 * @desc validate pattern of token
 * @param token
 */
export const isValidToken = (token: string) => {
  return token.length > 0
}

/**
 * @desc validate pattern of guid
 * @param guid
 */
export const isValidGuid = (guid: string): boolean => {
  const pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i')
  return pattern.test(guid)
}

export const pathnameFromSecondParams = () => {
  const pathname = window.location.pathname.split('/')
  return pathname.slice(2, pathname.length + 1)
}
