import { concat, Observable, Observer, of } from 'rxjs'
import { last, mergeMap } from 'rxjs/operators'
import { DIDOMI_CONSENT_ID } from '../../app/environment/const'
import { getDistributorCodeFromGdoCookie } from '../../app/helpers/utils'
import { DefaultServiceProvidedInterface } from '../../app/models/ServiceProvider'
import { XHRRequestObservable } from '../../app/service/XHRRequest/XHRRequestObservable'
import { DatadogApiClient } from '../api-service/DatadogApiClient'
import { FacadeApiClient, FacadeApiClientInterface } from '../api-service/FacadeApiClient'
import { FactorySCApiPayload, FactorySCApiPayloadInterface } from '../api-service/FactorySCApiPayload'
import { ShopCodeManager } from '../components/shipping/pick-up/StoreMapHelper'
import { RETAILER_USER } from '../environment/const'
import { ServiceProvidedInterface } from '../model/CustomServiceProvider'
import { AmplitudeEvent } from '../service/AmplitudeEvent'
import { AmplitudeRootStoreMiddleware } from '../service/AmplitudeRootStoreMiddleware'
import { CouponManager } from '../service/CouponManager'
import { Geographics, GeographicsServiceInterface } from '../service/GeographicsData'
import { GTMEvent } from '../service/GTMEvent'
import { GTMRootStoreMiddleware } from '../service/GTMRootStoreMiddleware'
import { ShippingAddress } from '../service/ShippingAddress'
import { ShippingMethodService } from '../service/ShippingMethodService'
import ShoppingCartDataService from '../service/ShoppingCartData'
import { ConsumerUserInfoStore } from '../state-manager/ConsumerUserInfoStore'
import ConsumerUserMarketingDataStore from '../state-manager/ConsumerUserMarketingDataStore'
import { OrderStore } from '../state-manager/OrderStore'
import { PaymentMethodStore } from '../state-manager/PaymentMethodStore'
import { ProductsHeaderStore } from '../state-manager/ProductsHeaderStore'
import { QueryStringParamsAcceptedStore } from '../state-manager/QueryStringParamsAcceptedStore'
import { RetailerUserInfoStore } from '../state-manager/RetailerUserInfoStore'
import { ShippingAddressesStore } from '../state-manager/ShippingAddresses'
import { ShippingDataForRetailerStore } from '../state-manager/ShippingDataForRetailer'
import { ShippingMethodStore } from '../state-manager/ShippingMethodStore'
import { ShopForPickUp } from '../state-manager/ShopForPickUp'
import { ShoppingCartHeaderStore } from '../state-manager/ShoppingCartHeaderStore'
import { ShoppingCartStore } from '../state-manager/ShoppingCartStore'
import { UserInfoStore } from '../state-manager/UserInfoStore'
import UserTypesStore from '../state-manager/UserTypesStore'
import { ShoppingCartHydrate } from './ShippingcartHydrate'
import { ShoppingCartLoader, ShoppingCartLoaderInitializedInterface } from './ShoppingCartLoader'

interface initializeCustomService {
  serviceProvided: DefaultServiceProvidedInterface
  guid?: string
  token: string
  platform: string
}

export const initializeCustomService = ({
  serviceProvided,
  guid,
  token,
  platform
}: initializeCustomService): Observable<ServiceProvidedInterface> => {
  return new Observable((observer: Observer<any>) => {
    const { i18n, storage, sessionStorage, tagmanager } = serviceProvided

    /**
     * Create Payload for ShoppingCart ApiClient
     */
    const payloadShoppingCartApiClient: FactorySCApiPayloadInterface = new FactorySCApiPayload({ guid, token })

    /**
     * Create Shopping cart client with Payload and xhr observable service
     */
    const scApiClient: FacadeApiClientInterface = new FacadeApiClient(
      i18n,
      payloadShoppingCartApiClient,
      new XHRRequestObservable(),
      storage
    )

    const distributorCode = getDistributorCodeFromGdoCookie()
    if (distributorCode && distributorCode.length > 0) {
      scApiClient.setDistributor(distributorCode)
    }

    /**
     * Initialize ShoppingCart Status with state Manager
     */
    const shoppingCartloader = new ShoppingCartLoader(
      storage,
      {
        headerStore: ShoppingCartHeaderStore,
        orderStore: OrderStore,
        productsHeaderStore: ProductsHeaderStore,
        shippingMethodStore: ShippingMethodStore,
        shippingAddressesStore: ShippingAddressesStore,
        shopForPickUp: ShopForPickUp,
        paymentMethod: PaymentMethodStore,
        userInfo: UserInfoStore,
        userTypes: UserTypesStore,
        consumerUserInfo: ConsumerUserInfoStore,
        retailerUserInfo: RetailerUserInfoStore,
        shippingDataForRetailer: ShippingDataForRetailerStore,
        consumerUserMarketingData: ConsumerUserMarketingDataStore,
        queryStringParamsAccepted: QueryStringParamsAcceptedStore
      },
      ShoppingCartStore
    )

    const rootStore = shoppingCartloader.createStore()

    const couponManager = new CouponManager(scApiClient, rootStore)

    /**
     * Shipping address service
     */
    const shippingAddressService = new ShippingAddress(scApiClient, rootStore)

    const amplitudeEvent = new AmplitudeEvent(rootStore, storage)
    const shoppingCartDataService = new ShoppingCartDataService(
      scApiClient,
      rootStore,
      shippingAddressService,
      storage,
      amplitudeEvent,
      couponManager
    )

    /*
    hydrate storage
    */
    const hydrateRootStore = new ShoppingCartHydrate(shoppingCartDataService, shippingAddressService, storage, rootStore)

    /**
     * @desc try to get cart from api with guid and token
     */
    hydrateRootStore
      .getCartData$()
      .pipe(
        // prepare retailer data after service initialization
        mergeMap(
          (loader: ShoppingCartLoaderInitializedInterface): Observable<any> => {
            //dont patch retailer data if unique_retailer cookie exists and usertype is not retailer
            if (loader.rootStore.userType === RETAILER_USER) {
              return of(loader)
            }
            return concat(loader.cartDataService.originIsRetailerDataResolve$(), of(loader)).pipe(last())
          }
        )
      )
      .subscribe(
        (loader: ServiceProvidedInterface) => {
          /**
           * service ready
           */
          const { rootStore, cartDataService, storage } = loader

          const geographicsService: GeographicsServiceInterface = new Geographics(sessionStorage, scApiClient)
          const gtmEvent = new GTMEvent(rootStore, tagmanager)

          // Start Amplitude immediately if Didomi will not do it
          if (!DIDOMI_CONSENT_ID) {
            amplitudeEvent.initClient(platform)
          }

          /**
           * @desc add distributor in root store
           */
          scApiClient.setDistributor(rootStore.getDistributor())
          scApiClient.setConfigurationCode(rootStore.getConfigurationCode())

          const shopCodeManager = new ShopCodeManager(rootStore)

          const shippingMethodService = new ShippingMethodService(scApiClient, rootStore)

          /**
           * @desc list of provided service via injection with mobx provider component
           */
          const ShoppingCartService: ServiceProvidedInterface = Object.assign(serviceProvided, {
            //SHOPPING CART-SERVICE
            rootStore,
            scApiClient,
            shippingMethodService,
            shippingAddressService,
            geographics: geographicsService,
            cartDataService,
            couponManager,
            dataDogApiClient: new DatadogApiClient(new XHRRequestObservable()),
            gtmEvent,
            gtmRootStoreMiddleWare: new GTMRootStoreMiddleware(rootStore, gtmEvent),
            shopCodeManager,
            amplitudeEvent,
            amplitudeRootStoreMiddleWare: new AmplitudeRootStoreMiddleware(rootStore, amplitudeEvent, storage)
          })

          observer.next(ShoppingCartService)
        },
        (err: Error) => observer.error(err),
        () => observer.complete()
      )
  })
}

export default initializeCustomService
