import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Picture from '../../app/components/Picture'
import { TransNS } from '../../app/components/TransNS'
import { PATH_MAINSITE } from '../../app/environment/const'
import { Desktop, Mobile } from '../../app/service/responsive/mediaQueries'
import emptyCart from './../../assets/images/svg/moon_with_rocket.svg'
import { HeaderBackButton } from './HeaderBackButton'
// import ProductAdviseItem from './cart-empty/ProductAdviseItem'
// import classic_print_image from './../../assets/images/webp/img classiche.webp'
// import vintage_print_image from './../../assets/images/webp/img vintage.webp'
// import { APP_PATH_EDITOR } from '../environment/const'
export interface OrangeCircleInterface {
  number: number
}
/**
 * @desc Orange circle with number on left of empty cart list
 * @param props
 */
const OrangeCircle: React.FC<OrangeCircleInterface> = ({ number }) => {
  return <div className="orange-circle ft-bold">{number}</div>
}

/**
 * @desc Container of ShoppingCart without products
 * @param props
 */
export const ShoppingCartEmpty: React.FC = () => {
  const { t } = useTranslation('empty_cart')
  const title = t('cart')

  useEffect(() => {
    const target = document.querySelector('.page-header #title')
    if (target) {
      target.innerHTML = title
    }
  })

  return (
    <div className="tc">
      <Mobile>
        <HeaderBackButton
          onClick={() => {
            location.href = PATH_MAINSITE
          }}
        />
      </Mobile>
      <section className="cart-empty tc">
        <Desktop>
          <h2 className="ft-regular title-cart">{title}</h2>
        </Desktop>
        <div className="cart-grid">
          <Desktop>
            <div id="empty-cart-left-column" className="cell tc medium-typo medium-tl">
              <h1 className="txt-orange thin-title uc">
                <TransNS i18nKey="empty_cart:quality_serive_security">
                  Qualità. Servizio.
                  <br /> Sicurezza.
                </TransNS>
              </h1>
              <ul id="empty-cart-list" className="ltn">
                <li>
                  <OrangeCircle number={1} />
                  <div>
                    <h5 className="ft-bold no-pad no-mar">
                      <TransNS i18nKey="empty_cart:n1_in_italy">N°1 in Italia</TransNS>
                    </h5>
                    <h5 className="ft-regular no-pad no-mar">
                      <TransNS i18nKey="empty_cart:print_emotion_from_1973">Stampiamo emozioni dal 1973</TransNS>
                    </h5>
                  </div>
                </li>
                <li>
                  <OrangeCircle number={2} />
                  <div>
                    <h5 className="ft-bold no-pad no-mar">
                      <TransNS i18nKey="empty_cart:100_satisfaction_or_printed">100% Soddisfatti o ristampati</TransNS>
                    </h5>
                    <h5 className="ft-regular no-pad no-mar">
                      <TransNS i18nKey="empty_cart:contact_for_problem_text">
                        Se non sarai completamente soffisfatto
                        <br />
                        contattaci e risolveremo ogni problema
                      </TransNS>
                    </h5>
                  </div>
                </li>
                <li>
                  <OrangeCircle number={3} />
                  <div>
                    <h5 className="ft-bold no-pad no-mar">
                      <TransNS i18nKey="empty_cart:safe_payment">Pagamento sicuro e certificato</TransNS>
                    </h5>
                    <h5 className="ft-regular no-pad no-mar">
                      <TransNS i18nKey="empty_cart:your_data_is_secured_by_ssl">
                        I tuoi dati sono criptati utilizzando
                        <br />
                        la protezione SSL (Secure Socket Layer)
                      </TransNS>
                    </h5>
                  </div>
                </li>
                <li>
                  <OrangeCircle number={4} />
                  <div>
                    <h5 className="ft-bold no-pad no-mar">
                      <TransNS i18nKey="empty_cart:100_made_in_italy">100% made in italy</TransNS>
                    </h5>
                    <h5 className="ft-regular no-pad no-mar">
                      <TransNS i18nKey="empty_cart:printed_with_responsability_in_riccione">
                        Stampiamo responsabilmente
                        <br />
                        nella nostra sede di Riccione
                      </TransNS>
                    </h5>
                  </div>
                </li>
              </ul>
            </div>
          </Desktop>
          <div id="empty-cart-right-column" className="cell tc medium-typo d-flex">
            <div className="content">
              <Picture className="sad-cart-image" src={emptyCart} />
              <h3 className="ft-bold subtitle-format">
                <TransNS i18nKey="empty_cart:your_cart_is_empty">C'è spazio nel tuo carrello!</TransNS>
              </h3>
              <h5 className="ft-regular">
                <TransNS i18nKey="empty_cart:start_load_your_cart">
                  inizia subito a riempirlo
                  <br />
                  con le tue emozioni
                </TransNS>
              </h5>
              <Desktop>
                <a href={PATH_MAINSITE}>
                  <button className="phs-button secondary button-empty-cart">
                    <TransNS i18nKey="empty_cart:create_now">Crea subito</TransNS>
                  </button>
                </a>
              </Desktop>
              <Mobile>
                <a href={PATH_MAINSITE}>
                  <button className="phs-button secondary button-empty-cart">
                    <TransNS i18nKey="empty_cart:create_now">Crea subito</TransNS>
                  </button>
                </a>
              </Mobile>
              {/* <Mobile>
                <ul id="empty-cart-product-advise-list">
                  <li>
                    <ProductAdviseItem
                      image={classic_print_image}
                      title={t('classic_print_title')}
                      subtitle={t('classic_print_subtitle')}
                      cta_text={t('start_to_create')}
                      cta_href={`${APP_PATH_EDITOR}?category=SMALL_SIZE&configurationId=1&productId=classic_prints`}
                    ></ProductAdviseItem>
                  </li>
                  <li>
                    <ProductAdviseItem
                      image={vintage_print_image}
                      title={t('vintage_print_title')}
                      subtitle={t('vintage_print_subtitle')}
                      cta_text={t('start_to_create')}
                      cta_href={`${APP_PATH_EDITOR}?category=VINTAGE&paper=MATTE&boxType=NO&configurationId=38&productId=vintage_prints`}
                    ></ProductAdviseItem>
                  </li>
                </ul>
              </Mobile> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default ShoppingCartEmpty
