import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface OrderStoreInterface extends Instance<typeof OrderStore> {}
export interface OrderStoreInterfaceSnapshotIn extends SnapshotIn<typeof OrderStore> {}
export interface OrderStoreInterfaceSnapshotOut extends SnapshotOut<typeof OrderStore> {}

export const OrderStore = types
  .model('OrderStore', {
    billingAddressArea: types.string,
    billingAddressCity: types.string,
    billingAddressCompanyName: types.string,
    billingAddressCountry: types.string,
    billingAddressFirstName: types.string,
    billingAddressId: types.string,
    billingAddressLastName: types.string,
    billingAddressNumber: types.string,
    billingAddressPhone: types.string,
    billingAddressStreet: types.string,
    billingAddressZipCode: types.string,
    confirmationDate: types.string,
    couponCodes: types.array(types.string),
    creationDate: types.string,
    currencyCode: types.string,
    deliveryDate: types.string,
    fixedCost: types.number,
    formattedFixedCost: types.string,
    formattedPaymentCost: types.string,
    formattedPointsValue: types.string,
    formattedPrice: types.string,
    formattedProductVoucherValue: types.string,
    formattedShippingCost: types.string,
    formattedShippingVat: types.string,
    formattedSubTotal: types.string,
    formattedTotalDiscount: types.string,
    formattedTotalShipping: types.string,
    formattedTotalVoucher: types.string,
    formattedVat: types.string,
    formattedVoucherValue: types.string,
    formattedVoucherVat: types.string,
    guid: types.string,
    id: types.number,
    note: types.string,
    number: types.number,
    numberOfProducts: types.number,
    orderState: types.string,
    paid: types.boolean,
    paymentCost: types.number,
    paymentType: types.string,
    paymentUrl: types.string,
    pointsSpent: types.number,
    pointsValue: types.number,
    price: types.number,
    productVoucherCode: types.string,
    productVoucherValue: types.number,
    shippingAddressAdditionalInfo: types.string,
    shippingAddressCareOf: types.string,
    shippingAddressArea: types.string,
    shippingAddressCity: types.string,
    shippingAddressCompanyName: types.string,
    shippingAddressCountry: types.string,
    shippingAddressEmail: types.string,
    shippingAddressFirstName: types.string,
    shippingAddressId: types.string,
    shippingAddressLastName: types.string,
    shippingAddressNumber: types.string,
    shippingAddressPhone: types.string,
    shippingAddressStreet: types.string,
    shippingAddressZipCode: types.string,
    shippingCost: types.number,
    shippingType: types.string,
    shippingVat: types.number,
    shopCode: types.string,
    subTotal: types.number,
    totalDiscount: types.number,
    totalShipping: types.number,
    totalVoucher: types.number,
    uploadState: types.string,
    userId: types.number,
    vat: types.number,
    version: types.string,
    voucherCode: types.string,
    voucherValue: types.number,
    voucherVat: types.number
  })
  .actions((self: any): any => ({}))

/*
  .actions((self: any): any => ({
    setId(id: string): void {
      self.id = id
    }
  }))
*/
export default OrderStore
