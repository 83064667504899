// GOOGLE TAG MANAGER
import TagManager, { DataLayerArgs, TagManagerArgs } from 'react-gtm-module'
import { Observable, Observer } from 'rxjs'
import { tap } from 'rxjs/operators'
import { DATALAYER_EVENT_CATEGORY, DATALAYER_NAME, GTM_ID } from '../environment/const'

declare global {
  interface Window {
    dataLayer: any
  }
}

export interface TagManagerInterface {
  dataLayer: (dataLayerArgs: DataLayerArgs) => void
  initialize: (tagManagerArgs: TagManagerArgs) => void
}

const tagManager$: Observable<TagManagerInterface> = new Observable((observer: Observer<TagManagerInterface>) => {
  /**
   * Start Google Tag Manager
   */
  TagManager.initialize({
    gtmId: GTM_ID,
    dataLayer: {
      userProject: DATALAYER_EVENT_CATEGORY
    },
    dataLayerName: DATALAYER_NAME
  })

  observer.next(TagManager)
  observer.complete()
}).pipe(
  tap(() => {
    const dataLayer = (() => {
      return {
        push: (value: object) => {
          window[DATALAYER_NAME].push(value)
        }
      }
    })()

    Object.assign(TagManager, { dataLayer: dataLayer })
  })
)

export default tagManager$
