import { inject, observer } from 'mobx-react'
import React from 'react'
import { GlobalsProvidedInterface } from '../../model/CustomGlobalsProvided'
import Modal, { ModalComponentInterface } from '../generic/Modal'

interface GlobalsContainerInterface {
  globalModal$?: ModalComponentInterface
  children?: any
}

export const GlobalsContainer: React.FC<GlobalsContainerInterface> = inject((states: GlobalsProvidedInterface) => ({
  globalModal$: states.globals.globalModal as ModalComponentInterface
}))(
  observer(({ globalModal$, children }) => {
    if (!globalModal$) {
      const ErrorGeneric = React.lazy(() => import('./../../../app/components/ErrorGeneric'))
      return <ErrorGeneric code={110013} i18nKeyMessage={'service_failed_injection'} />
    }

    const setModalVisbile = (condition: boolean) => {
      let defaultConfig = {}
      if (condition) {
        defaultConfig = { visible: condition, hideClose: false, dialogStyleOverride: {}, styleOverride: {} }
      } else {
        defaultConfig = { visible: condition, hideClose: false, dialogStyleOverride: {}, styleOverride: {}, confirm: false }
      }

      Object.assign(globalModal$, Object.assign(globalModal$, defaultConfig))
    }

    return (
      <div className="phs-consumer-cart phs-container tc">
        <Modal onClose={() => setModalVisbile(false)} {...globalModal$}></Modal>
        {children}
      </div>
    )
  })
)

export default GlobalsContainer
