import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface ShippingDataForRetailerStoreInterface extends Instance<typeof ShippingDataForRetailerStore> {}
export interface ShippingDataForRetailerStoreInterfaceSnapshotIn extends SnapshotIn<typeof ShippingDataForRetailerStore> {}
export interface ShippingDataForRetailerStoreInterfaceSnapshotOut extends SnapshotOut<typeof ShippingDataForRetailerStore> {}

export const ShippingDataForRetailerStore = types
  .model('ShippingDataForRetailerStore', {
    firstname: types.string,
    lastname: types.string,
    phone: types.string
  })
  .actions((self: any): any => ({
    setFirstname(firstname: string): void {
      self.firstname = firstname
    },
    setLastname(lastname: string): void {
      self.lastname = lastname
    },
    setPhone(phone: string): void {
      self.phone = phone
    },
    setShippingData(data: ShippingDataForRetailerStoreInterface) {
      self.firstname = data.firstname
      self.lastname = data.lastname
      self.phone = data.phone
    }
  }))

export default ShippingDataForRetailerStore
