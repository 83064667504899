import { ConsumerUserInfoStoreInterface } from '../state-manager/ConsumerUserInfoStore'
export const ConsumerUserInfoStoreMock: ConsumerUserInfoStoreInterface = {
  dateJoined: '',
  email: '',
  extraData: null,
  fullName: '',
  id: 0,
  iplabsRetailerId: null,
  isActive: true,
  isStaff: false,
  isValidProfile: false,
  name: '',
  phoneNumber: null,
  points: 0,
  retailer: { companyName: '', id: 0, iplabsRetailerId: 0, shopCode: '' },
  shippingAddress: null,
  shopCode: '',
  social: [],
  surname: '',
  taxcode: null,
  types: null
}
