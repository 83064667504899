import { getSnapshot } from 'mobx-state-tree'
import { concat, forkJoin, Observable, of } from 'rxjs'
import { flatMap, last, mergeMap, tap } from 'rxjs/operators'
import { StorageWrapperInterface } from '../../app/service/storage/StorageWrapper'
import { CONSUMER_USER, RETAILER_USER } from '../environment/const'
import { selfChooseUserTypes } from '../helpers/utils'
import { ShippingAddressInterface } from '../service/ShippingAddress'
import ShoppingCartDataService from '../service/ShoppingCartData'
import { RetailerUserInfoStoreInterface } from '../state-manager/RetailerUserInfoStore'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'
import { UserTypesStoreInterface } from '../state-manager/UserTypesStore'
import { ShoppingCartLoaderInitializedInterface } from './ShoppingCartLoader'

export class ShoppingCartHydrate {
  constructor(
    private shoppingCartDataService: ShoppingCartDataService,
    private shippingAddressService: ShippingAddressInterface,
    private storage: StorageWrapperInterface, //local storage
    private shoppingCartStore: ShoppingCartStoreInterface // store maanger // mobx state tree
  ) {}

  private getStore() {
    return this.shoppingCartStore
  }

  private getStorage() {
    return this.storage
  }

  /**
   * @desc return initialized shipping address service
   */
  public getAddressService(): ShippingAddressInterface {
    return this.shippingAddressService
  }

  /**
   * @desc get service for fetch data and save in store
   */
  public getShoppingDataService(): ShoppingCartDataService {
    return this.shoppingCartDataService
  }

  public getCartData$(): Observable<ShoppingCartLoaderInitializedInterface> {
    return forkJoin([
      this.shoppingCartDataService.getAndPersistHeadersAndProducts$(),
      this.shoppingCartDataService.getAndPersistUserTypes$()
    ]).pipe(
      mergeMap(() => {
        return this.shoppingCartDataService.getAndPersistShippingMethods$()
      }),
      mergeMap(() => {
        let userInfo$: Observable<unknown> = of({})

        const consumerUser$ = concat(
          this.shoppingCartDataService.getAndPersistConsumerUserMarketingData$(),
          this.shoppingCartDataService.getAndPersistConsumerUserInfo$()
        ).pipe(last())

        const userTypes: UserTypesStoreInterface = getSnapshot(this.getStore().userTypes)
        this.getStore().setUserType(selfChooseUserTypes(userTypes.types))

        switch (this.getStore().userType) {
          case RETAILER_USER:
            userInfo$ = this.shoppingCartDataService.getAndPersistRetailerUserInfo$().pipe(
              mergeMap((retailer: RetailerUserInfoStoreInterface) => {
                if (!this.shoppingCartStore.isValidProfile()) {
                  this.getStore().setUserType(CONSUMER_USER)
                  return consumerUser$
                }
                return of(retailer)
              })
            )
            break
          case CONSUMER_USER:
            userInfo$ = consumerUser$
            break
          default:
            throw 'unsupported userTypes'
        }

        return userInfo$
      }),
      tap((info) => {
        this.shoppingCartStore.setUserInfo(info)
      }),
      flatMap((emit) => {
        return of({
          storage: this.getStorage(),
          rootStore: this.getStore(),
          addressService: this.getAddressService(),
          cartDataService: this.getShoppingDataService()
        })
      })
    )
  }
}
