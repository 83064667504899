import React from 'react'

interface SwitchGuardComponentInterface {
  children?: any
  onlyFirst?: boolean
}
export const SwitchGuard: React.FC<SwitchGuardComponentInterface> = ({ children, onlyFirst }) => {
  if (!Array.isArray(children)) {
    return <>{children}</>
  }

  const childrens = children.filter((child: any) => {
    return child.props.condition === false ? null : child
  })

  if (onlyFirst === true) {
    return childrens[0] || null
  }

  return <>{childrens}</>
}
export default SwitchGuard
