import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

/**
 * @desc interface of single product in shopping cart
 */
export interface ProductStoreInterface extends Instance<typeof ProductStore> {}
export interface ProductStoreInterfaceSnapshotIn extends SnapshotIn<typeof ProductStore> {}
export interface ProductStoreInterfaceSnapshotOut extends SnapshotOut<typeof ProductStore> {}

/*
  Elenco dei prodotti in carrello
*/
export const ProductStore = types
  .model('ProductsStore', {
    id: types.string,
    itemId: types.optional(types.maybeNull(types.string), null),
    itemVersionKey: types.optional(types.maybeNull(types.string), null),
    price: types.number,
    unitPrice: types.number,
    formattedPrice: types.string,
    formattedPriceWithoutDiscount: types.string,
    discountPercentage: types.number,
    formattedUnitPrice: types.string,
    currency: types.string,
    description: types.string,
    title: types.string,
    productCategoryCode: types.string,
    quantity: types.number,
    quantityEditable: types.boolean,
    image: types.string,
    thumb: types.string,
    editUrl: types.optional(types.maybeNull(types.string), null),
    expirationDate: types.optional(types.maybeNull(types.string), null),
    itemInfoUrl: types.optional(types.maybeNull(types.string), null),
    productCode: types.string,
    productType: types.string,
    renewRetentionUrl: types.string,
    transactionId: types.string,
    previewUrl: types.optional(types.maybeNull(types.string), null),
    giftbox: types.boolean,
    userConfigurationId: types.maybeNull(types.string)
  })
  .actions((self: any): any => ({
    setId(id: string) {
      self.id = id
    },
    setItemId(itemId: string) {
      self.itemId = itemId
    },
    setItemVersionKey(itemVersionKey: string) {
      itemVersionKey = itemVersionKey
    },
    setPrice(value: number) {
      self.price = value
    },
    setUnitPrice(value: number) {
      self.unitPrice = value
    },
    setFormattedPrice(value: string) {
      self.formattedPrice = value
    },
    setFormattedUnitPrice(value: string) {
      self.formattedUnitPrice = value
    },
    setCurrency(currency: string) {
      self.currency = currency
    },
    setDescription(desc: string) {
      self.description = desc
    },
    setTitle(desc: string) {
      self.title = desc
    },
    setQuantity(quantity: number) {
      if (self.quantityEditable) {
        self.quantity = quantity
      }
    },
    setQuantityEditable(quantityEditable: number) {
      self.quantityEditable = quantityEditable
    },
    setImage(src: string) {
      self.image = src
    },
    setThumb(src: string) {
      self.thumb = src
    },
    setEditUrl(src: string) {
      self.editUrl = src
    },
    setExpirationDate(date: string) {
      self.expirationDate = date
    },
    setItemInfoUrl(src: string) {
      self.itemInfoUrl = src
    },
    setProductCode(productCode: string) {
      self.productCode = productCode
    },
    setRenewRetentionUrl(renewRetentionUrl: string) {
      self.renewRetentionUrl = renewRetentionUrl
    },
    setTransactionId(transactionId: string) {
      self.transactionId = transactionId
    },
    setPreviewUrl(previewUrl: string) {
      self.previewUrl = previewUrl
    },
    setGiftBox(gift: boolean) {
      self.giftbox = gift
    }
  }))

export default ProductStore
