import { Observable } from 'rxjs'
import { XHROptions } from '../../../app/service/XHRRequest/XHRRequestObservable'
import ApiEnv from '../../environment/api-env'
import { CountryApiResult } from '../../model/GeographicsInterface'
import { AbstractApiClient } from '../AbstractApiClient'
import { geographicsTypes } from '../FacadeApiClient'

export interface GeographicsApiClientInterface {
  getGeographics$(type: geographicsTypes, countryCode: string | null): Observable<CountryApiResult[]>
}

export class GeographicsApiClient extends AbstractApiClient {
  constructor({ xhrRequestObv, querystring }: any) {
    super(xhrRequestObv, querystring)
  }

  /**
   * @desc get geo infos
   * @param type: geographicsTypes
   * @return {Observable}
   */
  getGeographics$(type: geographicsTypes, countryCode: string | null = null): Observable<CountryApiResult[]> {
    const url: Array<string> = [`${ApiEnv.geographics.url}${type}`]
    if (type == 'areas' && countryCode) {
      url.push(`countryCode=${countryCode}`)
    }

    const params: XHROptions = {
      url: url.join('?')
    }
    return this.get$(params)
  }
}
