import { toJS } from 'mobx'
import { setUserInfoCookie } from '../../app/helpers/utils'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'
import { UserInfoStoreInterfaceSnapshotOut } from '../state-manager/UserInfoStore'

export const setUserInfo = (rootStore: ShoppingCartStoreInterface) => {
  const userInfo: UserInfoStoreInterfaceSnapshotOut = toJS(rootStore.userInfo)

  const getPartialEmail = (email: string): string => email.split('@')[0] || ''
  const name = userInfo.name && userInfo.name.length > 0 ? userInfo.name : getPartialEmail(userInfo.email)

  if (userInfo && userInfo.id && userInfo.email && name) {
    setUserInfoCookie(userInfo.id, userInfo.email, name)
  }
}
