import { inject, observer } from 'mobx-react'
import React from 'react'
import ErrorGeneric from '../../app/components/ErrorGeneric'
import RouteGuard from '../../app/components/RouteGuard'
import SwitchGuard from '../../app/components/SwitchGuard'
import { GlobalsProvidedInterface } from '../model/CustomGlobalsProvided'
import { ProviderServiceInterface } from '../model/CustomServiceProvider'
import { ShoppingCartStoreAcceptedStatusList } from '../model/ShoppingCartStoreAcceptedStatus'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'
import GlobalSpinnerController from './globals/GlobalSpinnerController'
import { RootStoreMiddleware } from './middleware/RootStoreMiddleware'

export interface ShoppingCartContainerInteface {
  readonly rootStore?: ShoppingCartStoreInterface
}

/**
 * @desc Container of ShoppingCart, load only required components and logics
 * @param props
 */
export const ShoppingCartContainer: React.FC<ShoppingCartContainerInteface> = inject(
  (states: ProviderServiceInterface & GlobalsProvidedInterface) => ({
    rootStore: states.service.rootStore
  })
)(
  observer(({ rootStore }) => {
    if (!rootStore) {
      const ErrorGeneric = React.lazy(() => import('../../app/components/ErrorGeneric'))
      return <ErrorGeneric code={110045} i18nKeyMessage={'service_failed_injection'} />
    }

    const ShoppingCartEmpty = React.lazy(() => import('./CartEmpty'))
    const CartComponent = React.lazy(() => import('./CartComponent'))

    const isLoading = (() => {
      return rootStore.status != ShoppingCartStoreAcceptedStatusList.ready && rootStore.status != ShoppingCartStoreAcceptedStatusList.error
    })()

    const emptyCart = (() => {
      return rootStore.productRowsQty() <= 0 && rootStore.status == ShoppingCartStoreAcceptedStatusList.ready
    })()

    const cartError = (() => {
      return rootStore.status === ShoppingCartStoreAcceptedStatusList.error
    })()

    /**
     * @desc during error status, if you try to change history, page as reloaded
     */
    if (cartError) {
      window.onpopstate = () => window.location.reload()
    }

    return (
      <div className="phs-consumer-cart phs-container tc">
        <SwitchGuard onlyFirst={true}>
          <RouteGuard condition={cartError}>
            <ErrorGeneric code={110006} message={rootStore.errorMessage} rootStore={rootStore} />
          </RouteGuard>

          <RouteGuard condition={isLoading}>
            <GlobalSpinnerController visible={true} />
          </RouteGuard>

          <RouteGuard condition={emptyCart === true}>
            <ShoppingCartEmpty />
          </RouteGuard>

          <RouteGuard condition={emptyCart === false}>
            <RootStoreMiddleware>
              <CartComponent />
            </RootStoreMiddleware>
          </RouteGuard>
        </SwitchGuard>
      </div>
    )
  })
)

export default ShoppingCartContainer
