import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { shippingStoreAddressAdapter } from '../adapter/ShippingAddressStoreAdapter'
import { FacadeApiClientInterface } from '../api-service/FacadeApiClient'
import { ShippingAddressApiResultInterface } from '../model/api-result/ShippingAddressApiResult'
import { ShippingAddressStoreInterface } from '../state-manager/ShippingAddress'
import { ShippingAddressesStoreInterface } from '../state-manager/ShippingAddresses'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'

export interface ShippingAddressInterface {
  getAll(): void
  get$(): Observable<any>
  setAddresses(addressesList: ShippingAddressesStoreInterface): void
}

/**
 * @class ShippingAddress
 */
export class ShippingAddress implements ShippingAddressInterface {
  constructor(private shoppingCartApi: FacadeApiClientInterface, private shoppingCartStore: ShoppingCartStoreInterface) {}
  /**
   * @desc get and persist shipping address of users
   */

  get$(): Observable<ShippingAddressStoreInterface> {
    const shippingAddresses$ = this.shoppingCartApi.getShippingAddresses$()
    return shippingAddresses$.pipe(
      map((addresses: ShippingAddressApiResultInterface[]) => {
        return addresses.map((address: ShippingAddressApiResultInterface) => shippingStoreAddressAdapter(address))
      })
    )
  }
  getAll(): void {
    this.get$().subscribe((addresses: ShippingAddressStoreInterface) => {
      this.setAddresses(addresses)
    })
  }
  setAddresses(addressesList: ShippingAddressesStoreInterface): void {
    addressesList = addressesList.map((a: ShippingAddressApiResultInterface) => shippingStoreAddressAdapter(a))
    this.shoppingCartStore.shippingAddresses.setAddresses(addressesList)
  }
}
