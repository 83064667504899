import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { isDev } from '../../app/helpers/utils'

interface HeaderBackButtonInterface {
  path?: string
  onClick?: Function
}

export const HeaderBackButton = ({ path = '', onClick }: HeaderBackButtonInterface) => {
  const { t } = useTranslation('generic')
  const label = t('history_back')
  const history = useHistory()

  useEffect(() => {
    const targetContainer = (document.querySelector('.page-header [id="back-button"] .back-button-content') as HTMLDivElement) || null
    const target = (document.querySelector('.page-header [id="back-button"] a') as HTMLLinkElement) || null

    if (targetContainer && (path !== '' || onClick)) {
      targetContainer.style.display = 'inline-flex'
    } else {
      targetContainer.style.display = 'none'
    }

    if (target && (path || onClick)) {
      target.innerHTML = label

      target.onclick = (event) => {
        event.preventDefault()

        if (!onClick) {
          if (isDev()) console.log(path)
          history.push(path)
        } else {
          onClick()
        }
      }
    }
  })

  return <></>
}
