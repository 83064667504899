import { inject } from 'mobx-react'
import React, { useEffect } from 'react'
import { ProviderServiceInterface } from '../../model/CustomServiceProvider'
import { AmplitudeRootStoreMiddlewareInterface } from '../../service/AmplitudeRootStoreMiddleware'
import { GTMRootStoreMiddlewareInterface } from '../../service/GTMRootStoreMiddleware'

export interface RootStoreMiddlewareComponentInterface {
  readonly amplitudeStoreMiddleware?: AmplitudeRootStoreMiddlewareInterface
  readonly gtmRootStoreMiddleWare?: GTMRootStoreMiddlewareInterface
  children?: any
}

export const RootStoreMiddleware: React.FC<RootStoreMiddlewareComponentInterface> = inject((states: ProviderServiceInterface) => ({
  amplitudeStoreMiddleware: states.service.amplitudeRootStoreMiddleWare,
  gtmRootStoreMiddleWare: states.service.gtmRootStoreMiddleWare
}))(({ gtmRootStoreMiddleWare, children, amplitudeStoreMiddleware }) => {
  if (!gtmRootStoreMiddleWare || !amplitudeStoreMiddleware) {
    const ErrorGeneric = React.lazy(() => import('../../../app/components/ErrorGeneric'))
    return <ErrorGeneric code={110014} i18nKeyMessage={'service_failed_injection'} />
  }

  useEffect(() => {
    gtmRootStoreMiddleWare.listenAction()
    gtmRootStoreMiddleWare.listenPatch()
    amplitudeStoreMiddleware.listenAction()
    amplitudeStoreMiddleware.listenPatch()
    //gtmrootstoremiddleware.middleware()
  }, [])

  return <>{children}</>
})
