import { inject } from 'mobx-react'
import React, { useEffect } from 'react'
import { SpinnerComponentInterface } from '../../../app/components/Spinner'
import { GlobalsProvidedInterface } from '../../model/CustomGlobalsProvided'

interface GlobalSpinnerControllerComponentInterface {
  globalSpinner$?: SpinnerComponentInterface
  children?: any
  visible?: boolean
}

const GlobalSpinnerController: React.FC<GlobalSpinnerControllerComponentInterface> = inject((states: GlobalsProvidedInterface) => ({
  globalSpinner$: states.globals.globalSpinner
}))(({ children, globalSpinner$, visible }) => {
  if (!globalSpinner$) {
    return <></>
  }
  useEffect(() => {
    globalSpinner$.visible = visible
  }, [])
  return <>{children}</>
})

export default GlobalSpinnerController
