import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { AreaStore, AreaStoreInterface } from './AreaStore'
import { CountryStore, CountryStoreInterface } from './CountryStore'
/**
 * @desc interface geo data store
 */
export interface GeographicsStoreInterface extends Instance<typeof GeographicsStore> {}
export interface GeographicsStoreInterfaceSnapshotIn extends SnapshotIn<typeof GeographicsStore> {}
export interface GeographicsStoreInterfaceSnapshotOut extends SnapshotOut<typeof GeographicsStore> {}

export const GeographicsStore = types
  .model('GeographicsStore', {
    areas: types.array(AreaStore),
    countries: types.array(CountryStore)
  })
  .actions((self: any): any => ({
    setAreas(areas: AreaStoreInterface[]) {
      self.areas = areas
    },
    clearAreas(): void {
      self.areas.clear()
    },
    setCountries(countries: CountryStoreInterface[]) {
      self.countries = countries
    },
    clearCountries(): void {
      self.countries.clear()
    }
  }))

export default GeographicsStore
