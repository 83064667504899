// CONSTANTS
// SENTRY REPORT ISSUES
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'
import { CaptureConsole } from '@sentry/integrations'
import { SENTRY_DNS } from '../environment/const'
// ERROR INTERFACES
import { ErrorLevelInfo } from '../models/error/ErrorLevelInfo'
import { ErrorLevelWarning } from '../models/error/ErrorLevelWarning'

/**
 * Sentry initialize
 */
Sentry.init({
  dsn: SENTRY_DNS,
  integrations: [
    new CaptureConsole({
      levels: ['error']
    })
  ],
  beforeSend: function (event: any, hint: any) {
    const exception = hint.originalException
    if (exception instanceof ErrorLevelWarning) {
      event.fingerprint = ['warning_message', event.message || 'warning_message']
      event.level = Severity.Warning
    }

    if (exception instanceof ErrorLevelInfo) {
      event.fingerprint = ['info_message', event.message || 'info_message']
      event.level = Severity.Info
    }
    return event
  }
})

export default Sentry
