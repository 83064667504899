import React from 'react'
import Lottie from 'react-lottie'
import loaderJson from './../../assets/animations/Loader.json'

const Loader: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  }

  const width = Math.min(window.innerWidth / 1.2, 400)

  return <Lottie options={defaultOptions} width={width}></Lottie>
}

export default Loader
