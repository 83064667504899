import { RetailerUserInfoStoreInterface } from '../state-manager/RetailerUserInfoStore'
export const RetailerUserInfoStoreMock: RetailerUserInfoStoreInterface = {
  authorizeBehaviorProfiling: false,
  authorizeMarketingCommunications: false,
  companyName: '',
  email: '',
  extraData: '',
  fullName: '',
  gdprProcessorPartnerAcceptDate: '',
  gdprProcessorPhotosiAcceptDate: '',
  hasPermissionBecomeRetailerUser: false,
  id: 0,
  iplabsRetailerId: 0,
  isActive: false,
  isStaff: false,
  isValidProfile: false,
  name: '',
  phoneNumber: '',
  shopCode: '',
  showDeliveryNotes: false,
  showInvoices: false,
  surname: '',
  taxcode: '',
  types: null
}
