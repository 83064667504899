import { Observable } from 'rxjs'
import { isDev } from '../../app/helpers/utils'
import {
  bodyParam,
  CustomRequestHeaderItemInterface,
  XHROptions,
  XHRRequestObservableInterface
} from '../../app/service/XHRRequest/XHRRequestObservable'
import { DATADOG_APP_KEY, DATADOG_X_API_KEY } from '../environment/const'
import { AbstractApiClient } from './AbstractApiClient'

export interface DatadogPutMetricsRequest {
  description?: string
  per_unit?: string
  short_name?: string
  statsd_interval?: number
  type?: string
  unit?: string
}

export interface DatadogPutMetricsResponse {
  description: string
  integration: string
  per_unit: string
  short_name: string
  statsd_interval: number
  type: string
  unit: string
}

export interface DatadogPostMetricsResponse {
  status: string
}

export interface DatadogApiClientInterface {
  putMetrics(metricName: string, data: DatadogPutMetricsRequest): Observable<DatadogPutMetricsResponse>
  postMetrics(metricName: string, value: Array<any> | number): Observable<DatadogPostMetricsResponse>
}

export class DatadogApiClient extends AbstractApiClient implements DatadogApiClientInterface {
  private xApiKeyHeader: CustomRequestHeaderItemInterface = { key: 'DD-API-KEY', value: DATADOG_X_API_KEY }
  private applicationKey: CustomRequestHeaderItemInterface = { key: 'DD-APPLICATION-KEY', value: DATADOG_APP_KEY }
  private metricPrfix: string = isDev() ? 'shopping_cart_web_consumer_dev' : 'shopping_cart_web_consumer'
  private endpoint: string = 'https://api.datadoghq.eu/api/v1/'
  constructor(public xhrRequestObv: XHRRequestObservableInterface) {
    super(xhrRequestObv)
    this.xhrRequestObv = xhrRequestObv
  }

  /**
   * @desc put metric to datadog api
   */
  putMetrics(metricName: string, data: DatadogPutMetricsRequest): Observable<DatadogPutMetricsResponse> {
    const bodyParams: bodyParam[] = this.getBodyParams(data)

    const params: XHROptions = {
      method: 'PUT',
      CustumRequestHeader: [this.applicationKey, this.xApiKeyHeader],
      body: bodyParams,
      url: `${this.endpoint}metrics/${metricName}`
    }
    return this.get$(params)
  }

  /**
   * @desc post metric to datadog api
   */
  postMetrics(metricName: string, value: Array<any> | number): Observable<DatadogPostMetricsResponse> {
    const data = {
      series: [
        {
          metric: `${this.metricPrfix}.${metricName}`,
          points: value
        }
      ]
    }

    const bodyParams: bodyParam[] = this.getBodyParams(data)

    const params: XHROptions = {
      method: 'POST',
      CustumRequestHeader: [this.applicationKey, this.xApiKeyHeader],
      body: bodyParams,
      url: `${this.endpoint}/series`
    }
    return this.get$(params)
  }
}

export default DatadogApiClient
