import { addMiddleware, getSnapshot } from 'mobx-state-tree'
import { from, Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { isDev } from '../../app/helpers/utils'
import { StorageWrapperInterface } from '../../app/service/storage/StorageWrapper'
import { AreasAdapter } from '../adapter/AreasAdapter'
import { CountriesAdapter } from '../adapter/CountryAdapter'
import { FacadeApiClientInterface } from '../api-service/FacadeApiClient'
import { SHOPPING_CART_GEOGRAPHICS_ITEMKEY_STORAGE } from '../environment/const'
import { AreasApiResult, CountryApiResult } from '../model/GeographicsInterface'
import { AreaStoreInterface } from '../state-manager/AreaStore'
import { CountryStoreInterface } from '../state-manager/CountryStore'
import GeographicsStore, { GeographicsStoreInterface } from '../state-manager/GeographicsDataStore'

/**
 * @desc interface of geographics service
 */
export interface GeographicsServiceInterface {
  getAreas$(country: string): Observable<any>
  getCountries$(): Observable<any>
  clearAreas(): void
  clearCountries(): void
}

/**
 * @desc geographics service, return countries and areas, and cache in storage
 */
export class Geographics implements GeographicsServiceInterface {
  private geographicsStore: GeographicsStoreInterface = GeographicsStore.create({ areas: [], countries: [] })
  constructor(private storage: StorageWrapperInterface, private apiClient: FacadeApiClientInterface) {
    addMiddleware(this.geographicsStore, (call: any, next: any, abort: any) => {
      /**
       * persist store on call actions
       */
      next(call, (value: any) => {
        this.persistSnapshot()
        return value
      })
    })
  }

  private persistSnapshot() {
    this.storage.persist(SHOPPING_CART_GEOGRAPHICS_ITEMKEY_STORAGE, JSON.stringify(getSnapshot(this.geographicsStore)))
  }

  /**
   * @desc get areas from local memory or remote api, with autonomous capacity to decide
   * @return {Observable}
   */
  getAreas$(countryAlpha2: string): Observable<any> {
    const areasApiClient$ = this.apiClient.getGeographics$('areas', countryAlpha2).pipe(
      tap((areas: Array<any>) => {
        if (areas) {
          this.setAreas(areas)
        }
      })
    )

    // FOR DEV IN CASE OF CRASH; GET DATA FROM LOCAL MOCK
    if (isDev()) {
      return areasApiClient$.pipe(
        catchError(() => {
          return from(import(`./../dev-mock/areas/${countryAlpha2}.json`)).pipe(
            map((data: any) => data.default),
            tap((areas: AreaStoreInterface[]) => this.geographicsStore.setAreas(areas))
          )
        })
      )
    }

    return areasApiClient$
  }

  /**
   * @desc clear store manager
   */
  clearAreas() {
    this.geographicsStore.clearAreas()
  }

  /**
   * @desc get countries from local memory or remote api, with autonomous capacity to decide
   * @return {Observable}
   */
  getCountries$(): Observable<any> {
    const storeCountries: CountryStoreInterface[] = getSnapshot(this.geographicsStore.countries)

    const countriesApiClient$ = this.apiClient.getGeographics$('countries').pipe(
      tap((countries: any) => {
        if (countries) {
          this.setCountries(countries)
        }
      })
    )

    const storeCountries$ = of(storeCountries)

    const getCountries$ = storeCountries$.pipe(
      mergeMap((countries: CountryApiResult[]): any => {
        if (countries.length > 0) {
          return of(countries)
        }
        return countriesApiClient$
      })
    )

    // FOR DEV IN CASE OF CRASH; GET DATA FROM LOCAL MOCK
    if (isDev()) {
      return getCountries$.pipe(
        catchError(() => {
          return from(import('./../dev-mock/countries.json')).pipe(
            map((data: any) => data.default),
            tap((countries: CountryStoreInterface[]) => this.geographicsStore.setCountries(countries))
          )
        })
      )
    }
    return getCountries$
  }

  /**
   * @desc clear store manager
   */
  clearCountries() {
    this.geographicsStore.clearCountries()
  }

  /**
   * @desc set in countryStore the data getted from api
   * @param countries
   */
  private setCountries = (countries: CountryApiResult[]): void => {
    this.geographicsStore.setCountries(CountriesAdapter(countries))
    this.geographicsStore.setAreas([])
  }

  /**
   * @desc set in areasStore the data getted from api
   * @param areas
   */
  private setAreas = (areas: AreasApiResult[]): void => {
    this.geographicsStore.setAreas(AreasAdapter(areas))
  }
}
